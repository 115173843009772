/* ================================================================
   Resources Listing Carousel
================================================================ */

.resources-listing-slider {
	
}
.resources-listing-slider__header {
   @include breakpoint(mw) {
      display: flex;
      align-items: center;
   }
}
.resources-listing-slider__heading {
   @include breakpoint(mw) {
      margin-bottom: 0;
   }
}
.resources-listing-slider__explore-link {
   margin-left: auto;
   font-weight: $fontWeight-bold;
   display: flex;
   align-items: center;

   svg {
      fill: $color-primary;
      width: 16px;
      height: 16px;
      margin-left: 4px;
   }
}
.resources-listing-slider__container-wrapper {
   position: relative;
   padding-top: 40px;

   @include breakpoint(mw) {
      padding-top: 60px;
   }
}
.resources-listing-slider__container {
   padding: 0 0 0 $grid-gutter;

   @include breakpoint(mw) {
      padding: 0 95px;
   }
   @include breakpoint($container-size-large) {
      padding: 0;
      padding-left: calc(50vw - #{$container-size}/2);
   }
}

.resources-listing-slider__item {
   padding: 5px;
   display: flex !important;
   flex-direction: column;

   @include breakpoint(mw) {
      margin-right: 30px;
   }
   
}

.resources-listing-slider__card-type {
   font-family: $font-secondary;
   text-transform: uppercase;
   letter-spacing: pxtorem(1.75px);
   font-size: pxtorem(14px);
   font-weight: $fontWeight-bold;
   display: inline-block;
   padding: 3px 7px;
   margin-bottom: 15px;
   width: fit-content;

}
.resources-listing-slider__item {
   &:nth-child(1n){
      .resources-listing-slider__card {
         background-color: $color-primary;
         color: $color-invert;
      }
      .resources-listing-slider__card-type {
         background-color: $color-invert;
         color: $color-primary;
      }
      .resources-listing-slider__card-heading {
         color: $color-invert;
      }
   }
   &:nth-child(2n){
      .resources-listing-slider__card {
         background-color: $color-secondary;
         color: $color-invert;
      }
      .resources-listing-slider__card-type {
         background-color: $color-invert;
         color: $color-secondary;
      }
      .resources-listing-slider__card-heading {
         color: $color-invert;
      }

   }
   &:nth-child(3n){
      .resources-listing-slider__card {
         background-color: #F2F2F1;
         color: $color-base;
      }
      .resources-listing-slider__card-type {
         background-color: $color-secondary;
         color: $color-invert;
      }
      .resources-listing-slider__card-heading {
         color: $color-secondary;
      }
   }
}
.resources-listing-slider__card {
   display: flex;
   flex-direction: column;
   height: 100%;
   border-radius: 40px;
   box-shadow: 0 2px 8px rgba(51, 51, 51, 0.25);
   
}
.resources-listing-slider__figure {
   width: 45%;
   margin-left: auto;
   border-radius: 0 0 0 50%;
   overflow: hidden;
   clip-path: url(#resourcesClip);
}
#resourcesClip {
   height: 0;
   width: 0;
   font-size: 0;
   line-height: 0;
}
.resources-listing-slider__img {
   display:block;
   background: no-repeat center center / cover;
   border-radius: 0 40px 0 0;
   &::after { 
       content: ''; 
       display: block; 
       padding-top: percentage(1/1); 
   }
}
.resources-listing-slider__card-type {

}
.resources-listing-slider__card {
   
}
.resources-listing-slider__item:not(.slick-active) {
   position: relative;
   &::after {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(255,255,255, .5);
   }
}
.resources-listing-slider__card-heading {
   font-size: pxtorem(24px);
   line-height: 1.2;
   margin-bottom: 10px;

   @include breakpoint(mw) {
      font-size: pxtorem(36px);
      margin-bottom: 16px;
   }
}
.resources-listing-slider__card-header + .resources-listing-slider__card-body {
   margin-top: -40px;
   padding-top: 0;
}
.resources-listing-slider__card-body {
   padding: 40px 40px 40px;
   display: flex;
   flex-direction: column;
   height: inherit;

   @include breakpoint(mw) {
      padding-left: 60px;
      padding-bottom: 60px;
   }
}
.resources-listing-slider__card-text {
   font-size: pxtorem(14px);
   margin-bottom: 30px;

   @include breakpoint(mw) {
      font-size: pxtorem(16px);
      margin-bottom: 40px;
   }

}
.resources-listing-slider__card-btn {
   margin-top: auto;
   width: fit-content;
}

/******************************** 
** Slider Nav 
********************************/
.resources-listing-slider__btn {
   @include resetBtn;
   align-items: center;
   width: 90px;
   height: 90px;
   border-radius: 50%;
   display: none;
   z-index: 1;

   justify-content: center;
   background: $color-invert;
   box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.2);

   &.slick-disabled {
      display: none !important;
   }

   @include breakpoint(mw) {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
   }

   svg {
      width: 30px;
      height: 30px;
      fill: $color-primary;
   }

   &:hover, 
   &:active, 
   &:focus {
      background-color: $color-primary;

      svg {
         fill: $color-invert;
      }
   }

   &--prev {
      transform: rotate(180deg);
      left: 10px;

      @include breakpoint($container-size-large) {
         left: calc( 50vw - #{$container-size-large}/2 + 70px);
      }
   }

   &--next {
      right: 10px;
      @include breakpoint($container-size-large) {
         right: 40px;
      }
   }
}
.resources-listing-slider__dots {
   padding: 0 $grid-gutter;
   .slick-dots {
      @include resetList;
      display: flex;
      margin-top: 40px;
   }
   .slick-dots li {
      flex: 1;
      height: 4px;
      display: block;
      background-color: $color-gray;
      font-size: 0;
   }
   .slick-dots li button{
      @include resetBtn;
      width: 100%;
      padding: 0;
      border: 5px solid transparent;
      position: relative;
      top: -3px;
   }
   .slick-dots li.slick-active button {
      border: 5px solid $color-dark-gray;
      border-radius: 5px;
   }
}
.resources-listing-slider {
   .slick-track
   {
       display: flex !important;
   }
   
   .slick-slide
   {
       height: inherit !important;
   }
}