.widget-tab {
    text-align: center;

    &__description {
        p {
            max-width: 60rem;
            margin: .5rem auto;
            font-size: pxtorem(16px);
        }

    }

    &__wrapper {
        margin: 5rem 0;
    }

    &__buttons {
        @include flexBox($flexalign: flex-end);
        flex-wrap: nowrap;
    }

    &__button {
        @include flexBox($flexjustify: flex-start);
        cursor: pointer;
        border: 0;
        flex-wrap: nowrap;
    }

    &__desktopBtn {
        display: none;

        @include breakpoint (mw) {
            display: flex;
            width: 100%;
            max-width: pxtorem(330px);
            padding: 2rem;
            margin: 0 .5rem;
            border-radius: 20px 20px 0 0;
            background: $color-primary;
        }

        &.active {
            background: $color-light-gray;

            .widget-tab__icon {
                .icon {
                    fill: $color-primary;
                }
            }

            .widget-tab__btnText {
                color: $color-secondary;
            }
        }
    }

    &__mobileBtn {
        @include flexBox($flexjustify: flex-start);
        position: relative;
        border-radius: 20px;
        background: $color-primary;
        width: 100%;
        padding: 2rem 2rem;
        margin: .5rem 0;
        font-weight: 700;

        &::after {
            content: '';
            position: absolute;
            right: 30px;
            width: 40px;
            height: 40px;
            background:
                linear-gradient($color-invert 0 0),
                linear-gradient($color-invert 0 0);
            background-position:center;
            background-size: 50% 2px,2px 50%; /*thickness = 2px, length = 50% (20px)*/
            background-repeat:no-repeat;
            border-radius:50%;
        }
        &::before {
            content: '';
            position: absolute;
            right: 30px;
            width: 40px;
            height: 40px;
            background-color: $color-orange;
            border-radius:50%;
            border: 2px solid $color-orange;
        }

        @include breakpoint(mw) {
            display: none;

            &::after {
                display: none;
            }
        }


        &.active {
            background: $color-light-gray;
            border-radius: 20px 20px 0 0;
            margin-bottom: 0;

            .widget-tab__icon {
                .icon {
                    fill: $color-primary;
                }
            }

            .widget-tab__btnText {
                color: $color-secondary;
            }

            &::after {
                background: linear-gradient($color-orange 0 0);
                background-position:center;
                background-size: 50% 2px,2px 50%;
                background-repeat:no-repeat;
            }
            &::before {
                background-color: transparent;
            }
        }

    }

    &__icon {
        .icon {
            height: 60px;
            width: 60px;
            fill: $color-invert;

            @include breakpoint(mw) {
                height: 90px;
                width: 90px;
            }
        }
    }

    &__btnText {
        font-family: $font-secondary;
        font-size: pxtorem(21px);
        margin-left: 2rem;
        color: $color-invert;

        @include breakpoint (mw) {
            font-size: pxtorem(36px);
            margin-left: 1rem;
        }
    }

    &__content {
        position: relative;
        top: -2px;
        padding: 1rem;
        background-color: $color-light-gray;
        border-radius: 0 0 30px 30px;
        text-align: left;
        color: $color-secondary;

        @include breakpoint (mw) {
            padding: 3rem 2rem;
            border-radius: 30px;

        }
    }

    &__content-outer {
        @include flexBox($flexalign: flex-start);
    }

    &__content-inner {
        padding: .25rem 1rem;

        @include breakpoint (xl) {
            flex: 1;
        }
    }

    &__content-title {
        font-size: pxtorem(18px);
        font-weight: 700;

        @include breakpoint (mw) {
            font-size: pxtorem(26px);
        }
    }

    &__link {
        margin: 1rem;
        text-align: center;

        .btn {
            padding: 10px 28px;
        }
    }
}
.widget-tab__button img {
    max-height: 50px;
    filter: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7423%) hue-rotate(92deg) brightness(106%) contrast(100%);
}
.widget-tab__button.active img {
    filter: brightness(0) saturate(100%) invert(29%) sepia(69%) saturate(1723%) hue-rotate(143deg) brightness(94%) contrast(102%);
}

.widget-tab {
    [data-expander-target].target-show,
    [data-expander-target].target-hide {
        animation: none;
    }
    [data-expander-target].target-hide {
        position: absolute;
        top: 0;
        z-index: -9999;
    }
}