/* ================================================================
   Filters
   ================================================================ */

.filters {
    margin:0 0 1rem;
    padding:15px 0;
    @include breakpoint(mw) {
        display: flex;
        align-items: center;
        padding-bottom: 60px;
        border-bottom: 1px solid $color-gray;
        margin-bottom: 60px;
    }
}

.filter {
    margin:0 0 15px;
    @include breakpoint(mw) {
        margin-bottom: 0;
        margin-right: 15px;
    }

    .btn {
        padding-right: 0;
    }
}

.filter__label {
    display:block;
    margin:0 0 3px;
    white-space: nowrap;
}
.filter__label--date {
    font-size:.875em;
    padding:0 5px 0 0;
}

.filter__select {
    width:100%;
}

.filter__input {
    width:100%;
}
.filter__input--date {
    width:70px;
}

.filter__fieldset {
    margin:0 0 15px;
}

.filter__legend {
    display:block;
    margin:0 0 3px;
    width:100%;
}

.filter__fieldset-content {
    display:flex;
}

.filter__inner-field {
    padding:0 10px 0 0;
}

.filter__search-content {
    position:relative;
}

.filter__search-button {
    position:absolute;
    background:none;
    border:0;
    padding:0;
    width:20px;
    height:20px;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: $color-primary;

    &:hover,
    &:focus {
        color: $color-secondary;
    }

    svg {
        fill: currentColor;
        width:100%;
        height:100%;
        transition: $trans;
    }
}

.filter--nolabel {
    &::before {
        @extend .filter__label;
        content: '\a0'; // non breaking space
    }
}

.filter--last {
    text-align: right;

    @include breakpoint(mw) {
        text-align: center;
        margin-left: auto;
        margin-right: 0;
    }
}

.filter--search {
    .form-control {
        border-radius: 50px;
        width: 100%;
    }
    @include breakpoint(mw) {
        width: 450px;
    }
}