/* ================================================================
   Button Styles
   ================================================================ */

   .button,
   .btn {
		display:inline-block;
		padding: 9px 20px;
		border: 2px solid transparent;
		border-radius: 30px;
		font-family:$font-primary;
		font-size: pxtorem(16px);
		font-weight: $fontWeight-bold;
		color:$color-invert;
		text-decoration:none; text-align:center;
		background:$color-primary;
		cursor: pointer;
		transition: $trans;
		line-height:1.4;

		@include breakpoint(mw) {
			font-size: pxtorem(19px);
			padding: 15px 20px;
		}

		&:hover, &:focus {
			text-decoration:none;
			opacity: .8;
		}

		&--orange {
			background-color: $color-orange;
		}
		&--orange {
			background-color: $color-orange;
		}

		&--blue {
			background-color: $color-blue;
		}

		&--white {
			background-color: $color-invert;
			color: $color-primary;
		}
		&--plain-green {
			background-color: transparent;
			color: $color-primary;
		}
		&--border-orange {
			background-color: transparent;
			color: $color-orange;
			border-color: $color-orange;
		}
		&--plain {
			background-color: transparent;
			color: $color-primary;
		}
   }
   .button-2 {
	   display:inline-block;
	   padding:0.47em 1.2em;
	   border:0;
	   font-family:$font-primary;
	   color:$color-invert;
	   text-decoration:none; text-align:center;
	   background:$color-secondary;
	   cursor: pointer;
	   transition: $trans;
	   line-height:1.4;
   
	   &:hover, &:focus {
		   text-decoration:none;
		   background:$color-invert;
		   color:$color-primary;
	   }
   }
   
   .back {
	   @extend .button-small !optional;
	   text-transform: capitalize;
   }
   
   .more {
	   @extend .button-small !optional;
	   text-transform:capitalize;
   }

   .play {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 140px;
		height: 140px;
		border-radius: 50%;
		top: 0;
    	bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		background-color: $color-invert;
		&::before {
			content: "";
			display: block;
			width: 0; 
			height: 0; 
			border-top: 30px solid transparent;
			border-bottom: 30px solid transparent;			
			border-left: 40px solid $color-orange;
			position: relative;
			left: 5px;
		}
   }
   
   %carousel-btn {
	   @include resetBtn;
	   position: absolute;
	   width: 60px;
	   height: 60px;
	   top: 50%;
	   transform: translateY(-50%);
	   z-index: 5;
	   font-size: 0;
	   color: $color-primary;
	   transition: $trans;
	   background: none;
	   border: none;
	   cursor: pointer;
	   padding: 0;
   
	   svg {
		   display: inline-block;
		   width: 100%;
		   height: 100%;
		   fill: currentColor;
		   transition: $trans;
	   }
   
	   &:hover,
	   &:focus {
		   color: $color-secondary;
	   }
   
	   @media (min-width: $bp-mw) {
		   width: 60px;
		   height: 60px;
	   }
   }

%chevron {
	&:before {
		content: '';
		border-style: solid;
		border-width: 2px 2px 0 0;
		display: inline-block;
		height: 10px;
		width: 10px;
		position: relative;
		vertical-align: top;
		transform: rotate(135deg);
	}
	&.active {
		&::before {
			transform: rotate(-45deg);			
		}
	}
}

.slider-btn {
  @include resetBtn;
  display: none !important;
  align-items: center;
  width: 90px;
  height: 90px;
  border-radius: 50%;

  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.2485);
}
.slider-btn svg {
  width: 30px;
  height: 30px;
  fill: #008065;
}
.slider-btn--prev {
}
.slider-btn--prev svg {
  transform: rotate(180deg);
}
.slider-btn--next {
    background: $color-primary;
}
.slider-btn--next svg {
    fill: white !important;
}