.profile-card-listing {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -15px;
}
.profile-card-wrapper {
    flex: 0 1 100%;
    height: inherit;
    margin-bottom: 30px;

    @include breakpoint(mw) {
        flex: 0 1 50%;
    }
    @include breakpoint(l) {
        flex: 0 1 33.33%;
    }
}
.profile-card {
    padding: 40px;
    background: #ffffff;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: $trans;
    margin: 0 15px;
    min-height: 300px;
    height: 100%;

    justify-content: center;
    @include breakpoint(mw) {
        min-height: 540px;
    }

    &:hover, 
    &:active, 
    &:focus {
        text-decoration: none;
        box-shadow: 0 0 12px rgba(0,0,0,.1);
        background-color: $color-primary;
        
        .profile-card__title,
        .profile-card__subtitle {
            color: $color-invert;
        }
    }
}
.profile-card__image {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include breakpoint(mw) {        
        height: 250px;
        width: 250px;
        margin-bottom: 38px;
    }
}
.profile-card__title {
    margin: 10px 0 5px 0;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 700;
    @include x-rem(font-size, 18px);
    line-height: 24px;
    text-align: center;
    color: $color-primary;
    margin: 10px 0 5px 0;
    @include breakpoint(mw) {
        font-size: pxtorem(26px);
    }
}
.profile-card__subtitle {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    @include x-rem(font-size, 16px);
    line-height: 28px;
    text-align: center;
    color: #000000;
    margin: 0;
}

/******************************** 
** Profile Listing Rows Layout 
********************************/
.profile-card-listing--rows {
    display: block;

    .profile-card {
        min-height: auto;
        justify-content: flex-start;
        @include breakpoint(mw) {
            align-items: start;
            flex-direction: row;
        }
        &:hover, 
        &:active, 
        &:focus {
            box-shadow: none;
            background-color: #fff;
            
            .profile-card__title {
                color: $color-primary;
            }
            .profile-card__subtitle {
                color: initial;
            }
        }
    }
    .profile-card__image {
        @include breakpoint(mw) {
            margin-bottom: 0;
        }
    }
    .profile-card__content {
        width: 100%;
        flex: 1;
        @include breakpoint(mw) {
            margin-left: $grid-gutter;
        }
    }
    .profile-card__title,
    .profile-card__subtitle {
        @include breakpoint(mw) {
            text-align: left;
        }
    }
    .profile-card__subtitle {
        font-weight: $fontWeight-bold;
    }
    .profile-card__btn {
        display: flex;
        width: fit-content;
        margin:  $grid-gutter auto 0;
        @include breakpoint(mw) {
            margin-left: 0;
        }
    }
}