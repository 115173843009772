.book-carousel-container {
}

.book-carousel-slider-btn--prev {
  display: none !important;
}
.book-carousel-slider-btn--next {
  display: none !important;
}

.book-carousel {
}

.book-carousel__item {
  background: #f2f2f2;
  border-radius: 40px;
  margin-right: 30px;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  // max-width: 330px;
}

.book-carousel__item-img {
  min-width: 104px;
  min-height: 158px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  right: 0;
  margin: auto;
}

@include breakpoint($bp-m) {
  .book-carousel__item-img {
    min-width: 138.32px;
    min-height: 210px;
  }
}
