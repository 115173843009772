.related-resources-widget {
}
.related-resources-widget__top-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 10px;
  width: 100%;
  margin-bottom: 40px;
}
.related-resources-widget__top-section-header {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  @include x-rem(font-size, 32px);
  line-height: 38px;
  letter-spacing: -0.5px;
  color: #212121;
  margin: 0;
}

.related-resources-widget__top-section-link {
  max-width: fit-content;
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
  @include x-rem(font-size, 19px);
  line-height: 26px;
  display: flex;
  align-items: center;
  gap: 4px;
  letter-spacing: -0.316667px;
  color: $color-primary;
  text-decoration: none;
  &:hover, 
  &:active, 
  &:focus {
    text-decoration: underline;
  }
}

.related-resources-widget__top-section-link svg {
  fill: $color-primary;
  color: $color-primary;
  height: 16px;
  width: 16px;
}
.related-resources-widget__list-container {
}
.related-resources-widget__list {
  @include resetList;
}
.related-resources-widget__list .slick-track {
 
}
.related-resources-widget__list .slick-dots {
  @include resetList;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
  margin: 20px 0;
}

.related-resources-widget__list .slick-dots li button {
  @include resetBtn;
  border: 2px solid $color-primary;
  width: 12px;
  height: 12px;
  font-size: 0;
  border-radius: 50%;
}

.related-resources-widget__list .slick-dots li.slick-active button {
  background-color: $color-primary;
}

.related-resources-widget__list-item {
 
}

.related-resources-widget__list-item-inner {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    
}
.related-resources-widget__list-item-media {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  min-height: 188px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &--video {
         &::before {
        transition: all 0.2s ease;
        @include pseudo;

        
        background-color: $color-invert;
        width: 85px;
        height: 85px;
        border-radius: 100%;
      }
      &::after {
        transition: all 0.2s ease;
        @include pseudo;
        border-style: solid;
        margin-left: 7px;
        border-color: #202020;

        border-width:18px 0px 18px 24px;
        border-color: transparent blue transparent $color-orange;
        background: none;
        min-width: unset !important;
      }

      &:hover,
      &:focus {
        &::before {
           width: 91px;
        height: 91px;
        }
        &::after {
          border-width:23px 0px 23px 30px;
        }
      }
  }
}
.related-resources-widget__list-item-tag {
font-family: $font-secondary;
font-style: normal;
font-weight: 700;
  @include x-rem(font-size, 14px);
line-height: 12px;
letter-spacing: 1.75px;
text-transform: uppercase;
color: $color-invert;

background-color: $color-secondary;
padding: 7px;
width: fit-content;
margin-top: 5px;
}
.related-resources-widget__list-item-title {
    font-family: $font-secondary;
font-style: normal;
font-weight: 700;
  @include x-rem(font-size, 18px);
line-height: 24px;
color: #212121;
}
.related-resources-widget__list-item-description {
    font-family: $font-primary;
font-style: normal;
font-weight: 400;
  @include x-rem(font-size, 16px);
line-height: 28px;
color: #212121;
}
.related-resources-widget__list-item-link {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 700;
    @include x-rem(font-size, 19px);
    line-height: 26px;
    display: flex;
    align-items: center;
    gap: 4px;
    letter-spacing: -0.316667px;
    color: $color-orange;
    text-decoration: none;

    &:hover, 
    &:active, 
    &:focus {
      text-decoration: underline;
    }
}

.related-resources-widget__list-item-link svg {
width: 16px;
height: 16px;
fill: $color-orange;

}

.related-resources-widget__list-item {
}


@include breakpoint(700px) {
    .related-resources-widget__list-item:not(:first-of-type) .related-resources-widget__list-item-inner {

    margin-left: 30px;
}
 .related-resources-widget__list-item .related-resources-widget__list-item-inner {
    transition: all .6s ease-in-out;
  
 }
 .related-resources-widget__list-item.slick-current .related-resources-widget__list-item-inner {
    transition: all .6s ease-in-out;
    margin-left: 0;
 }
}

@include breakpoint($bp-mw) {

.related-resources-widget__top-section {
  margin-bottom: 60px;
}
.related-resources-widget__top-section-header {
  @include x-rem(font-size, 60px);
line-height: 70px;
}


.related-resources-widget__list-item-inner {
    row-gap: 20px;
}
.related-resources-widget__list-item-tag {
margin-top: 15px;
}
.related-resources-widget__list-item-title {
  @include x-rem(font-size, 26px);
  line-height: 34px;
  margin-bottom: 0;
}
}