/*================================================================
            MAIN NAVIGATION STYLES
================================================================*/

.main-navigation {
    background-color: #fff;

    //Nav Defaults

    ul {
        list-style: none;
        padding:0;
        margin:0;
    }
    li {           

    }
    a,
    button {
        display:block;
        width:100%;
        border:0;
        padding:10px;
        color:$color-base;
        text-align: left;
        background:none;
        cursor:pointer;
        font-family: $font-secondary;
        font-size: pxtorem(16px);
        text-decoration: none;

        &:hover,
        &:focus {
            text-decoration: none;
            opacity: .8;
        }

        &[data-expander-toggle] {
            position: relative;

            // Arrows
            &:after {
                content: '';
                border-style: solid;
                border-width: 2px 2px 0 0;
                display: inline-block;
                height: 8px;
                width: 8px;
                position: absolute;
                top: 21px;
                right: 10px;
                vertical-align: top;
                transform: rotate(45deg);
                border-color: $color-primary;
            }

        }
    }    
     
    // 1st Level
    // ********************************************* //
    > ul {
        margin: 20px;

        > li {

            > a,
            > button {
                padding: 12px 10px;
                border-bottom: 2px solid $color-gray;
                font-size: pxtorem(26px);

                &:hover,
                &:focus {
                    
                }
            }
            
            .main-navigation-mega__sub {                        
                position: absolute;
                background-color: #fff;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                padding: 0 20px 20px;

                .main-navigation__heading {
                    @include breakpoint(mw) {
                        display: none;
                    }
                }
            }
               
            [data-expander-target].target-show {
                animation-name: toggleTargetShowSide;
            }

            [data-expander-target].target-hide {
                animation-name: toggleTargetHideSide;
            }

            // 2nd Level
            // ********************************************* //
            > ul,
            .main-navigation-mega > ul {

                > li {

                    > a,
                    > button {
                        padding: 15px 10px;
                        border-bottom: 2px solid $color-gray;
                        font-size: pxtorem(16px);

                        &:hover,
                        &:focus {
                            
                        }
                    }

        
                    // 3rd Level
                    // ********************************************* //
                    
                    > ul,
                    .main-navigation-mega__sub ul {
    
                        > li {
    
                            > a,
                            > button {
                                padding: 15px 10px;
                                border-bottom: 2px solid $color-gray;
    
                                &:hover,
                                &:focus {
                                }
                            }       
                            
                            // 4th Level
                            // ********************************************* //
                            
                            > ul {
                                > li {
            
                                    > a,
                                    > button {
            
                                        &:hover,
                                        &:focus {
                                        }
                                    }                                
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: $bp-mw) {

        //Nav Defaults

        ul {

        }
        li {

        }
        a,
        button {
            border-bottom: none !important;
            line-height: 1;

            &:hover,
            &:focus {

            }
        }        

        // 1st Level
        // ********************************************* //
        > ul {
            display:flex;
            justify-content: space-between;
            position: static;

            > li {

                
                &[data-type="mega"] {
                    position: static;
                }

                > a,
                > button {
                    text-align: center;
                    background:none;
                    padding: 10px 30px;
                    font-size: pxtorem(24px);
                    position: relative;
                    
                    &.active {
                        &::before {
                            content: '';
                            width: 60px;
                            height: 60px;
                            background-color: $color-primary;
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -100px;
                            margin: auto;
                            animation-name: toggleTargetShow;
                        }
                    }

                    &[data-expander-toggle] {
                        position: relative;
            
                        // Arrows
                        &:after {
                            border-color: #333 transparent transparent transparent;
                            display: none;
                        }
            
                        &.active {
                            &:after {
                                border-color: transparent transparent #333  transparent;
                            }
                        }
                    }
                }
                
                .main-navigation-mega__sub {
                    position: absolute;
                    background-color: #fff;
                    z-index: 1;
                    top: inherit;
                    left: inherit;
                    width: auto;
                    height: auto;
                    padding: 20px;
    
                    .main-navigation__heading {
                        @include breakpoint(mw) {
                            display: none;
                        }
                    }
                }  

                [data-expander-target].target-show {
                    animation-name: toggleTargetShow;
                }
        
                [data-expander-target].target-hide {
                    animation-name: toggleTargetHide;
                }

                // 2nd Level
                // ********************************************* //
                > ul {
                    position:absolute;
                    width:100%;
                    top:100%;
                    background-color: #fff;

                    > li {

                        > a,
                        > button {
                            color: $color-primary;
                            font-weight: $fontWeight-bold;
                            font-size: pxtorem(20px);

                            &:hover,
                            &:focus {
                                
                            }
                        }
            
                        // 3rd Level
                        // ********************************************* //
                        
                        > ul {
        
                            > li {
        
                                > a,
                                > button {
        
                                    &:hover,
                                    &:focus {
                                        
                                    }
                                }       
                                
                                // 4th Level
                                // ********************************************* //
                                
                                > ul {
                
                                    > li {
                
                                        > a,
                                        > button {
                
                                            &:hover,
                                            &:focus {
                                            }
                                        }                                
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Mega
            // ********************************************* //
            [data-nav-type="mega"] {
                position: static;

                .main-navigation-mega {

                    ul {
                        display:block;
                        animation: none;
                        background:none;
                    }

                    > ul {
                        padding: 0 $grid-gutter;

                        // Mega 1st Level
                        // ********************************************* //
                        > li {
                            break-inside:avoid;
                            margin-bottom: 5px;

                            > a,
                            > button {
                                color: $color-primary;
                                font-weight: $fontWeight-bold;
                                font-size: pxtorem(20px);
                                padding: 10px 20px;
                                font-family: $font-secondary;
                                line-height: 1;
                                margin-bottom: 0;

                                &:hover,
                                &:focus {
                                    background-color: $color-light-gray;
                                    border-radius: 40px;
                                    text-decoration: underline;
                                    color: $color-base;
                                }

                                &::after {
                                    display: none;
                                }
                            }     
                            .main-navigation-mega__sub {
                                display: block;
                                position: relative;
                                padding: 0;
                                height: auto;
                            }  

                            // Mega 2nd Level
                            // ********************************************* //
                            > ul, .main-navigation-mega__sub > ul {                                
                                display:block !important;

                                > li {
            
                                    > a,
                                    > button {
                                        font-size: pxtorem(16px);
                                        padding: 8px 20px;
                                        font-family: $font-secondary;
                                        line-height: 1;
                                        
                                        
                                        &:hover,
                                        &:focus,
                                        &.active {
                                            background-color: $color-light-gray;
                                            border-radius: 40px;
                                            text-decoration: underline;
                                        }
                                    }     
                                    
                                    // Mega 3rd Level
                                    // ********************************************* //
                                    > ul {
                                        
                                        > li {
                    
                                            > a,
                                            > button {
                                                font-size: pxtorem(16px);
                                                padding: 8px 20px;
                                                font-family: $font-secondary;
                                                line-height: 1;
                                               
                                                &:hover,
                                                &:focus,
                                                &.active {
                                                    background-color: $color-light-gray;
                                                    border-radius: 40px;
                                                    text-decoration: underline;
                                                }
                                            }                                
                                        }
                                    }
                                }
                            }
                        }
                    }
                    
                }
            }
        }
    }
    
    @include breakpoint(mw) {

        & > ul > li > a, & > ul > li > button {
            padding: 10px 20px;
            font-size: pxtorem(16px);
            transition: $trans;
        }
    }
    @include breakpoint(1499px) {

        & > ul > li > a, & > ul > li > button {
            padding: 10px 30px;
            font-size: pxtorem(24px);
        }
    }
 
    .mega {    
        padding:0 20px 20px;
        position: absolute;
        top:0;
        left:0;
        right: 0;
        margin: auto;
        width:100%;
        max-width: $container-size;
        min-height: 660px;
        background:#fff;
        z-index: 1;

        @include breakpoint(mw) {
            padding:50px;
            top:100%;
            border-radius: 0 0 40px 40px;
            box-shadow: 0 2px 8px rgba(51, 51, 51, .25);
            min-height: auto;
        }
    }
    .mega__inner {
        display: flex;
        flex-direction: column;

        @include breakpoint(mw) {
            flex-direction: row;
        }
    }

    .main-navigation__heading {
        border-bottom: 2px solid $color-gray;
        margin-bottom: 0;

        @include breakpoint(mw) {
            border-bottom: none;
            margin-bottom: 10px;
        }
        
    }

    .main-navigation__heading-link, .main-navigation__heading-span {
        color: $color-primary;
        line-height: 1;
        font-weight: $fontWeight-medium;
        position: relative;
        transition: $trans;
        font-size: pxtorem(21px);
        font-weight: $fontWeight-bold;
        margin: 0;
        display: inline-block;
        position: relative;
        padding-right: 20px;
        display: block;
        padding: 15px 10px;
        color: $color-primary;
        width: fit-content;

        @include breakpoint(mw) {
            font-size: pxtorem(36px);
            font-weight: $fontWeight-medium;
            margin-left: 30px;
            padding: 0 10px;
            padding-right: 20px;
        }

        
        @extend %chevron;

        &::before {
            transform: rotate(45deg);
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            border-width: 2px 2px 0 0;
            height: 8px;
            width: 8px;

            @include breakpoint(mw) {
                border-width: 4px 4px 0 0;
                height: 14px;
                width: 14px;
            }         
        }   
        &:hover, 
        &:active, 
        &:focus {
            &:before {
                right: -5px;
            }
        }

    }
    .main-navigation__heading-span {
        color: $color-base;
        &::before {
            display: none;
        }
    }

    .mega__left {
        order: 1;
        margin-top: 40px;

        @include breakpoint(mw) {
            flex: 1;
            max-width: 300px;
            padding-right: 30px;
            border-right: 2px solid $color-gray ;
            margin-right: 30px;
            order: 0;
            margin-top: 0;
        }
    }
    .mega__right {
        @include breakpoint(mw) {
            flex: 1;
        }
    }

    .menu-trending__heading {
        font-size: pxtorem(18px);
        margin-bottom: 10px;
        line-height: 1;
        font-family: $font-primary;
        @include breakpoint(mw) {
            font-size: pxtorem(26px);
            margin-bottom: 20px;
        }
    }
    .menu-trending__image {
        max-width: 100%;
        border-radius: 40px;
        margin: 20px 0;
    }
    .menu-trending__link {
        font-family: $font-primary;
        color: $color-primary;
        padding-left: 0;
        font-weight: $fontWeight-bold;
    }
    .menu-trending__desc {
        font-size: pxtorem(14px);
    }
    .main-navigation-back {
        color: $color-primary;
        text-transform: uppercase;
        font-weight: $fontWeight-bold;
        font-size: pxtorem(14px);
        position: relative;
        font-family: $font-secondary;
        padding: 20px 0 20px 20px;
        border-bottom: 2px solid $color-gray;

        @include breakpoint(mw) {
            display: none;
        }
        
        @extend %chevron;

        &::before {
            transform: rotate(225deg);
            position: absolute;
            left: 8px;
            top: 0;
            bottom: 0;
            margin: auto;
            border-width: 2px 2px 0 0;
            height: 8px;
            width: 8px;
        }
    }
}
.main-navigation > ul [data-nav-type="mega"] .main-navigation-mega > ul {
    @include breakpoint(mw) {
        column-count: 2;
    }
    @include breakpoint(l) {
        column-count: 3;
    }
}


.main-navigation > ul [data-nav-type="mega"] .main-navigation-mega > ul.mega-menu {
    column-count: 1;
    @include breakpoint(mw) {
        display: flex;
    }
}
.mega-menu-item {
    @include breakpoint(mw) {
        width: 33.33%;
    }
}
.mega-menu-item--66 {
    @include breakpoint(mw) {
        width: 66.66%;

        .main-navigation-mega__sub {
            ul {
                column-count: 2;
    
                li {
                    break-inside: avoid-column;
                }
            }
        }
        ul:first-child > li > a {
            width: 50%;
        }
    }
}
.mega-menu-item ul:first-child > li > a {
    
    @include breakpoint(mw) {
        color: $color-primary;
        font-weight: $fontWeight-bold;
        font-size: 1.25rem !important;
        padding: 10px 20px !important;
        margin-bottom: 0;

        &:hover, 
        &:active, 
        &:focus {
            color: $color-base;
        }
        &::after {
            display: none !important;
        }
    }   
}
.main-navigation > ul > li > button.active::before {
    @include breakpoint(mw) {
        transition: $trans;
        bottom: -65px;
    }
    @include breakpoint(1499px) {
        bottom: -100px;
    }
}

/******************************** 
** toggle links fix 
********************************/
.main-navigation .main-navigation-mega [data-expander-toggle] {
    display: block;
    @include breakpoint(mw) {
        display: none;
    }
}
.main-navigation .main-navigation-mega .link-desktop {
    display: none ;
    @include breakpoint(mw) {
        display: block ;
    }
}