/******************************** 
** Algolia T4T 
********************************/
.algolia-custom {
    .ais-Hits-list {
        flex-direction: column;
        margin: 20px auto;
    }
    .ais-Hits-item {
        width: 100%;
        box-shadow: none;
        padding: 0;
        border: none;
        margin: 0;
    }
	.ais-Hits-item em {
		font-style: normal;
		font-weight: $fontWeight-bold;
	}

    .ais-Breadcrumb,
    .ais-ClearRefinements,
    .ais-CurrentRefinements,
    .ais-GeoSearch,
    .ais-HierarchicalMenu,
    .ais-Hits,
    .ais-HitsPerPage,
    .ais-InfiniteHits,
    .ais-InfiniteResults,
    .ais-Menu,
    .ais-MenuSelect,
    .ais-NumericMenu,
    .ais-NumericSelector,
    .ais-Pagination,
    .ais-Panel,
    .ais-PoweredBy,
    .ais-RangeInput,
    .ais-RangeSlider,
    .ais-RatingMenu,
    .ais-RefinementList,
    .ais-Results,
    .ais-ResultsPerPage,
    .ais-SearchBox,
    .ais-SortBy,
    .ais-Stats,
    .ais-ToggleRefinement {
        color: $color-base;
    }
}

/******************************** 
** Facet Container for mobile
********************************/

.search-filter {
    display:flex;
    justify-content: space-between;
}
.search-filter__button {
    font-family: $font-primary;
    font-weight:700;
    font-size: pxtorem(19px);
    background:$color-primary;
    color:$color-invert;
    border-radius: 30px;
    padding:5px 20px;
    border:0;
    display:flex;
    align-items: center;
    height:44px;
    margin:0 0 0 15px;

    svg {
        width:24px;
        height:24px;
        fill:currentColor;
        margin:0 5px 0 0;
    }
}

.facet-container-outer {
    position: fixed;
    top:0;
    left:0;
    right:0;
    padding:20px 10px 20px 20px;
    background:#f2f2f2;
    height:100vh;
    overflow-y: scroll;
    z-index: 999;
}
.facet-container-outer__top {

}
.facet-container-outer__back {
    font-weight:700;
    font-family: $font-secondary;
    font-size: pxtorem(14px);
    position: relative;
    background:none;
    padding:0 0 0 12px;
    border:0;
    color:$color-primary;
    text-transform: uppercase;

    &:before {
        content:'';
        position: absolute;
        left:0;
        top:9px;
        width:8px;
        height:2px;
        background:$color-primary;
        transform: rotate(45deg);
    }
    &:after {
        content:'';
        position: absolute;
        left:0;
        top:4px;
        width:8px;
        height:2px;
        background:$color-primary;
        transform: rotate(-45deg);
    }
}
.facet-container-outer__heading {
    text-align: center;
    padding:20px 0;
    border-top:2px solid #E0E0E0;
    border-bottom:2px solid #E0E0E0;
    margin:20px 0 0 0;
}
.facet-container-outer__bottom {
    text-align: center;
    padding:40px 0 0;
}
.facet-container-outer__btn {
    min-width: 266px;
}

@include breakpoint(mw) {
    .search-filter {
        display:block;
    }
    .search-filter__button {
        display:none;
    }
    .facet-container-outer {
        display:block !important;
        position: static;
        background:none;
        padding:0;
        height:auto;
        overflow: hidden;
        z-index: 1;
    }
    .facet-container-outer__top,
    .facet-container-outer__bottom {
        display:none;
    }
}

/******************************** 
** Algolia Filters/Top
********************************/

.search-filters-top {
    margin:0 0 20px;

    .ais-CurrentRefinements {
        margin:0;
    }
    .ais-CurrentRefinements-item {
        margin:0 10px 0 0;
        padding:0;
        background:none;
        border-radius:0;
        flex-wrap: wrap; 
    }
    .ais-CurrentRefinements-label {
        color:#000;
        font-weight:700;
        font-size: pxtorem(16px);
    }
    .ais-CurrentRefinements-categoryLabel {
        color:#000;
        font-size: pxtorem(16px);
    }
    .ais-CurrentRefinements-delete {
        color:$color-primary;
        font-weight:600;
        font-size: pxtorem(16px);
    }

    .search-filters-top__clear {
        .button {
            background:transparent;
            padding:0;
            color:$color-primary;
            font-size: pxtorem(16px);
            font-weight:400;
            white-space: nowrap;

            &:hover,
            &:focus {
                text-decoration: underline;
                opacity:1;
            }
        }
    }
}

.search-pagination {
    margin:0 0 15px;
}
.search-pagination__left {
    display:none;
    align-items: center;
}
.search-pagination__left-label {
    font-size: pxtorem(16px);
    margin:0 5px 0 0;
}
.search-pagination__left-pagination {
    .button {
        border:2px solid $color-primary;
        background:transparent;
        color:$color-primary;
        border-radius: 20px;
        margin:0 0 0 10px;
        font-size: pxtorem(16px);
        font-weight:700;
        min-width:38px;
        padding:0;

        &.hits-per-page-active {
            background:$color-primary;
            color:#fff;
        }
    }
}

.search-pagination__right {
    
}
.search-pagination__right-label {
    font-size: pxtorem(16px);
    margin:0 0 15px;

    .ais-Stats-text {
        font-size: pxtorem(16px);
    }
}
.search-pagination__right-pagination {

    .ais-Pagination-item--firstPage,
    .ais-Pagination-item--lastPage {
        display:none;
    }
    .ais-Pagination-item  {
        margin:0 5px;

        &:last-child {
            margin-right:0;
        }
    }
    .ais-Pagination-link {
        border:2px solid $color-primary;
        background:transparent;
        color:$color-primary;
        border-radius: 20px;
        font-size: pxtorem(16px);
        font-weight:700;
        padding:0;
        width:30px;
        height:30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ais-Pagination-item--previousPage,
    .ais-Pagination-item--nextPage {
        .ais-Pagination-link {
            border:0;
            font-size: pxtorem(20px);
        }
    }
    .ais-Pagination-item--disabled {
        .ais-Pagination-link {
            color:$color-primary;
        }
    }
    .ais-Pagination-item--selected {
        .ais-Pagination-link {
            background:$color-primary;
            color:#fff;
            border:2px solid $color-primary;
        }
    }
}

@include breakpoint(mw) {
    .search-filters-top {
        display:flex;
        justify-content: space-between;
    }
    .search-pagination {
        display:flex;
        justify-content: space-between;
        margin:0 0 40px;
    }
    .search-pagination__right {
        display:flex;
        align-items: center;
    }
    .search-pagination__right-label {
        margin:0 10px 0 0;
    }
}
@include breakpoint(1140px) {
    .search-pagination__left {
        display:flex;
    }
}

/******************************** 
** Algolia Facets/Rail
********************************/

.algolia-custom {
    .ais-SearchBox {
        

        .ais-SearchBox-input {
            border-radius: 30px;
            height: 44px;
            padding:.3rem 1rem .3rem 2.3rem;
        }
        .ais-SearchBox-submit {
            left:12px;
        }
        .ais-SearchBox-submitIcon {
            width:16px;
            height:16px;
            fill:#000;
        }

        @include breakpoint(mw) {
            border-bottom: 2px solid #E0E0E0;
            padding:0 0 22px;

            .ais-SearchBox-input {
                height: 60px;
            }
        }
    }
    
    .ais-Panel {
        border-bottom: 2px solid #E0E0E0;
        padding:20px 0;
    }
    .ais-Panel-header {
        font-family: $font-secondary;
        font-size: pxtorem(20px);
        font-weight:700;
        text-transform: none;
        border:0;
        padding:0;
        margin:0;
    }
    .ais-Panel-footer {
        margin:0;
    }

    .panel-title {
        display:flex;
        justify-content: space-between;
        align-items: center;
    }
    .panel-title button[data-expander] {
        background:$color-orange;
        border:2px solid $color-orange;
        border-radius: 50%;
        padding:0;
        width:40px;
        height:40px;
        position: relative;
        cursor:pointer;

        &:before {
            content:'';
            width:3px;
            height:26px;
            background:#fff;
            position: absolute;
            top:50%;
            left:50%;
            transform: translateX(-50%) translateY(-50%);
        }
        &:after {
            content:'';
            width:26px;
            height:3px;
            background:#fff;
            position: absolute;
            top:50%;
            left:50%;
            transform: translateX(-50%) translateY(-50%);
        }
        &.active {
            background: transparent;
            &:before {
                display:none;
            }
            &:after {
                background:$color-orange;
            }
        }
    }

    .ais-Panel-body {
        padding-top:10px;
    }

    // Hide expanders on desktop
    @include breakpoint(mw) {
        .panel-title button[data-expander] {
            display:none;
        }
        .ais-Panel-body[data-expander-target] {
            display:block !important; 
        }
    }

    .ais-RefinementList-item {
        margin:0 0 5px;
    }

    .ais-RefinementList-label {
        position: relative;
        padding:0 0 0 24px;
        cursor:pointer;

        &:hover,
        &:focus {
            .ais-RefinementList-labelText {
                &:before {
                    background:$color-primary;
                }
            }
        }
    }
    .ais-RefinementList-checkbox {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        top:5px;
        left:5px;
        outline:none;

        &:focus ~ {
            .ais-RefinementList-labelText {
                &:before {
                    background:$color-primary;
                }
            }
        }
    }
    .ais-RefinementList-labelText {
        font-size: pxtorem(16px);
        font-weight: 400;

        &:before {
            content:'';
            position: absolute;
            top: 2px;
            left: 0;
            height: 18px;
            width: 18px;
            border:2px solid $color-primary;
            border-radius:3px;
        }

        &:after {
            content:'';
            position: absolute;
            left: 6px;
            top: 4px;
            width: 6px;
            height: 11px;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            display:none;
        }
    }
    .ais-RefinementList-checkbox:checked ~ .ais-RefinementList-labelText { 
        &:before {
            background:$color-primary;
        }

        &:after {
            display: block;
        }
    }
    .ais-RefinementList-count {
        padding:1px 5px;
        margin:0 0 0 3px;
    }

    .ais-RefinementList-showMore {
        border:0;
        padding:0;
        border-radius: 0;
        background:transparent;
        color:$color-primary;
        font-size: pxtorem(16px);
        font-weight: 700;

        &:after {
            content:"..."
        }

        &:hover,
        &:focus {
            text-decoration: underline;
            background: transparent;
        }
    }
    .search-date-container {
        padding:22px 0;
        border-bottom: 2px solid #E0E0E0;
    }
    .search-date-container__flex {
        display: flex;
        justify-content: space-between;

        input {
            background:#fff;
            border:1px solid #BDBDBD;
            border-radius: 5px;
        }
    }
    .custom-select__toggle--algolia {
        padding:0 16px;
        height:44px;
        min-height:44px;
        background:#fff;
        border:1px solid #BDBDBD;
        border-radius: 5px;
        font-size: pxtorem(16px);
        max-width: none;
        min-width: auto;
        width:100%;
    }
    .ais-Dropdown .ais-RefinementList {
        margin:0;
        width:100%;
        z-index: 20;

        .ais-SearchBox {
            padding:7px 0 15px;
            margin:0 0 15px;

            .ais-SearchBox-input {
                height:44px;
            }
        }
        .ais-RefinementList-list {
            max-height:200px;
            overflow: scroll;
        }
    }
}