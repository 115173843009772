/* ================================================================
   Global Site CSS Animations
   ================================================================ */


/* Fade In */
@keyframes fadeIn {
	100% { opacity:0; }
	0% { opacity:1; }
}


/* Fade Out */
@keyframes fadeOut {
	0% { opacity:1; }
	100% { opacity:0; }
}


@keyframes slide-in-left {
   0% {
     -webkit-transform: translateX(-500px);
     transform: translateX(-500px);
     opacity: 0;
   }
   75% {
     opacity: 0.75;
   }
   100% {
     -webkit-transform: translateX(0);
     transform: translateX(0);
     opacity: 1;
   }
 }
 @keyframes slide-in-right {
   0% {
     -webkit-transform: translateX(500px);
     transform: translateX(500px);
     opacity: 0;
   }
   75% {
     opacity: 0.75;
   }
   100% {
     -webkit-transform: translateX(0);
     transform: translateX(0);
     opacity: 1;
   }
 }

 @keyframes fade-in-bottom {
   0% {
     -webkit-transform: translateY(50px);
     transform: translateY(50px);
     opacity: 0;
   }
   100% {
     -webkit-transform: translateY(0);
     transform: translateY(0);
     opacity: 1;
   }
 }

/* ================================================================
   Site Specific Below
   ================================================================ */

