.location-card-container {
    text-align: center;
   
}
.location-card-container__title {

}
.location-card-container__subtitle {

}
.location-card-list {
display: flex;
flex-wrap: wrap;
gap: 30px;
@include resetList;
margin-top: 60px;
}
.location-card {
background-color: #F2F2F2;
padding: 30px;
text-align: left;
border-radius: 40px;
flex: 1 1 100%;
display: flex;
flex-direction: column;
min-height: 315px;
}
.location-card__subtitle {
    font-family: $font-secondary;
font-style: normal;
font-weight: 700;
font-size: .875rem;
line-height: 12px;
letter-spacing: 1.75px;
text-transform: uppercase;
color: #000000;

}
.location-card__title {
    font-family: $font-secondary;
font-style: normal;
font-weight: 700;
font-size: 1.3125rem;
line-height: 28px;
color: #212121;
margin: 10px 0 20px 0;
}
.location-card__text-bold {
font-family:$font-primary;
font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 28px;
color: #212121;
margin-bottom: 12px;
}

.location-card__text-regular {

}
.location-card__button {
margin-top: auto;
width: fit-content;
margin-top: 20px;

}

@include breakpoint($bp-sw) {
    .location-card {
flex: 1 1 48%;
}
    .location-card__button {
margin-top: auto;
width: fit-content;
padding-left: 50px;
padding-right: 50px;
}
}
@include breakpoint($bp-mw) {
    .location-card-list {
display: flex;
flex-direction: row;
gap: 30px;

}
.location-card {
background-color: #F2F2F2;
padding: 60px 40px;
text-align: left;
border-radius: 40px;
flex: 1 1 31%;
min-height: 436px;
}
.location-card__subtitle {
    font-family: $font-secondary;
font-style: normal;
font-weight: 700;
font-size: .875rem;
line-height: 18px;
letter-spacing: 1.75px;
text-transform: uppercase;
color: #000000;

}
.location-card__title {
    font-family: $font-secondary;
font-style: normal;
font-weight: 700;
font-size: 2.25rem;
line-height: 42px;
color: #212121;
margin: 10px 0 20px 0;
}
.location-card__text-bold {
font-family:$font-primary;
font-style: normal;
font-weight: 700;
font-size: 1rem;
line-height: 28px;
color: #212121;
margin-bottom: 12px;
}

.location-card__text-regular {
    line-height: 32px;
    margin-bottom: 12px;

}
.location-card__button {
margin-top: auto;
}

}
