.awards-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.awards-slider-container-wrapper {
  position: relative;
}
.awards-slider-container {
  padding: 0 0 0 $grid-gutter;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

  @include breakpoint(mw) {
     width: auto;
     position: static;
     margin-left: 0;
     margin-right: 0;
     padding: 0 90px;
  }
  @include breakpoint(1680px) {
     padding: 0 0;
  }
}

.awards-slider__header {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  @include x-rem(font-size, 32px);
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.5px;
  color: $color-secondary;
}
.awards-slider__description {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 300;
  @include x-rem(font-size, 20px);
  line-height: 30px;
  text-align: center;
  color: #212121;
  max-width: 800px;
  margin: auto;
}
.awards-slider__button {
  width: fit-content;
  margin: auto;
  left: 0;
  right: 0;
}

.awards-slider__item {
  padding: 5px;
  display: flex !important;
  flex-direction: column;
  margin: 0 15px;

}
.awards-slider__card {
  background: #ffffff;
  border-radius: 30px;
  padding: 40px 30px;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0 8px rgba(0,0,0, 0.1);
  height: 100%;

  flex-direction: column;
  @include breakpoint(mw) {
    border-radius: 40px;
  }
}
.awards-slider__item-figure {
  width: 100%;
  margin-bottom: 10px;
}
.awards-slider__item-img {
  display:block;
  background: no-repeat center center / contain;
  &::after { 
      content: ''; 
      display: block; 
      padding-top: percentage(110/180); 
  }
}

.awards-slider__item-description {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  @include x-rem(font-size, 16px);
  line-height: 28px;
  text-align: center;
  color: #000000;
}

@include breakpoint($bp-mw) {
  .awards-slider {
    margin: 40px 0;
  }
  .awards-slider__header {
    @include x-rem(font-size, 60px);
    line-height: 70px;
  }
}

/******************************** 
** Slider Nav 
********************************/
.awards-slider__btn {
  @extend .resources-listing-slider__btn; 

  &--prev {
     @extend .resources-listing-slider__btn--prev;

     @include breakpoint(1680px) {
        left: -120px;
     }
  }

  &--next {
     @extend .resources-listing-slider__btn--next;

     @include breakpoint(1680px) {
        right: -120px;
     }
  }
}
.awards-slider__dots {   
  @extend .resources-listing-slider__dots;
}
.awards-slider {
  .slick-track
  {
      display: flex !important;
  }
  .slick-slide
  {
      height: inherit !important;
  }
}