//Sticky Header On Scroll Up Defaulted to mobile only

.sticky-header {
    transition: transform .4s;
}

@media (max-width: $bp-m-max) {
    .sticky-header-mobile {
        position: fixed;
        top:0;
        left:0;
        right:0;
        z-index:999;

        //offset next element
        + * {
            padding-top:76px;
        }
    }
    &.sticky-header-down .sticky-header-mobile {
        transform: translate3d(0, -2000%, 0);
    }
}

//Sticky at all times on larger screens
@media (min-width: $bp-mw) {
    .sticky-header-desktop {
        position: fixed;
        top:0;
        left:0;
        right:0;
        z-index:999;
        transition: $trans;
    }
    
    .scrolled {
        &.sticky-header-desktop {
            top: -60px;
        }
        .header-logo {
            max-width: 160px;
            padding: 10px;
            margin: 0px;
        }
        .main-navigation > ul > li > a,
        .main-navigation > ul > li > button {
            font-size: pxtorem(16px);
        }
        .build-journey--desktop {
            font-size: pxtorem(14px);
            padding: 10px;
        }
        .main-navigation > ul > li > button.active::before {
            bottom: -65px;
        }
    }
}

.admin-container {
    .site-header {
        position: relative;
        z-index:1;
    }
}