/*
    Widget Styling for 8 boxes with gray background and primary font color.
    Classname goes by .widget-topics. This is specifically meant for Widget Topics.
*/

.widget-topics {
	
    &__description {
        max-width: 75rem;
        font-size: pxtorem(26px);
    }

    &__items {
        @include flexBox;
        margin: 2rem 0 1rem;
    }

    &__item {
        width: 100%;
        max-width: pxtorem(320px);
        text-align: center;
        padding: 0.75rem 1rem;
        margin: 0.75rem;
        background-color: $color-light-gray;
        border-radius: 30px;
		text-decoration: none;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            background-color: $color-primary;

            .widget-topics__title {
                color: $color-invert;
            }
        }
    }

    &__title {
        font-weight: 700;
    }
}



/*  Widget Styling for two boxes.
    Classname goes by .widget-twoBox.
*/

.widget-twoBox {

    > p {
        max-width: $heading-wrapper;
        font-size: pxtorem(16px);
    }

    &__items {
        @include flexBox($flexalign: stretch);
        margin: 2rem 0 1rem;
    }

    &__item {
        max-width: 100%;
        width: 100%;
        border-radius: 40px;
        margin: 1rem 0;
        padding: 1.5rem 2rem;

        &--green {
            background-color: $color-primary;
        }

        &--dark-blue {
            background-color: $color-secondary;
        }

        &--blue {
            background-color: $color-blue;
        }

        &--dark-green {
            background-color: $color-primary-dark;
        }

        @include breakpoint(mw) {
            width: calc(100% * (1 / 2) - 2rem);
            margin: 1rem;
        }
    }

    &__title {
        display: block;
        font-size: pxtorem(24px);
        font-family: $font-secondary;
        color: $color-invert;
        margin-bottom: 0.5rem;
		margin-top: 0;
		font-weight: $fontWeight-normal;

        @include breakpoint(mw) {
            font-size: pxtorem(36px);
        }
    }

    &__item-description {
        color: $color-invert;
        margin: 0;
        font-size: pxtorem(16px);
    }

    &__link {
        color: $color-invert;
        text-decoration: underline;
        font-weight: 700;
		&:hover, 
		&:active, 
		&:focus {
			text-decoration: none;
		}
    }

    /*  Widget Styling for two boxes.
        Classname goes by .widget-twoBox--text-left for items that have left aligned texts.
    */

    &--text-left {
        > p {
            max-width: 100%;
            font-size: pxtorem(20px);

            @include breakpoint(mw) {
                font-size: pxtorem(24px);
            }
        }

        .widget-twoBox__item {
            text-align: left;
        }
    }
}

/*  Widget Styling for six Icon boxes with 3 in a row with a blue background.
    Classname goes by .widget-sixIcons.
*/

.widget-sixIcons {
    &__items {
        @include flexBox;
        margin: 2.5rem 0;
        text-align: left;
    }

    &__item {
        @include flexBox($flexalign: flex-start, $flexwrap: nowrap);
        width: 100%;
        margin: 1rem;

        @include breakpoint(mw) {
            width: calc(100% * (1 / 2) - 3rem);
            margin: 1rem;
        }

        @include breakpoint(xl) {
            width: calc(100% * (1 / 3) - 6rem);
            margin: 1rem 3rem;
        }
    }

    &__title {
        font-family: $font-secondary;
        font-size: pxtorem(18px);
		margin: 0;

		@include breakpoint(mw) {
			font-size: pxtorem(24px);
			font-weight: normal;
		}
    }

    &__icon {
        min-width: 50px;
		height: 50px;
		margin-right: 20px;

		@include breakpoint(mw) {
			min-width: 60px;
			height: 60px;
		}

        .icon {
            fill: $color-primary;
        }
		img {
			height: 60px;
			width: 100%;
			filter: $filter-light-green;
		}
    }

    &__item-description {
        font-size: pxtorem(16px);
    }

    &__detail-wrapper {
        width: 100%;
    }
}

/*  Widget Styling for Utilites with Download and View PDF buttons.
    Classname goes by .widget-utilities.
*/

.widget-utilities {
    background-color: $color-invert;
    border-radius: 40px;
    padding: 2rem 0;
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);

    &__heading {
        font-size: pxtorem(24px);

        @include breakpoint(mw) {
            font-size: pxtorem(48px);
        }
    }

    &__wrapper {
        @include flexBox($flexwrap:nowrap);
    }

    &__utility {
		flex: 1;
        margin: 1rem 0;
        padding: 1rem;
        color: $color-primary;
        position: relative;
		text-decoration: none;

        &:not(:first-child) {
            &::after {
                @include pseudo;
                height: 100%;
                width: 2px;
                background-color: $color-gray;
                top: 0;
                right: 100%;
            }
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &__icon {
        display: block;
		margin-bottom: 20px;
        .icon {
            width: 90px;
            height: 90px;
            fill: $color-primary;
        }
		img {
			height: 90px;
			width: 100%;
			filter: $filter-green;
		}
    }

    &__title {
        font-weight: 700;
		margin: 0;
    }
}

/******************************** 
** Cards Styles 
********************************/
.widget-cards {
	&--small {
		max-width: 1122px;
		margin: auto;
	}
	&--grid {
		flex-wrap: wrap;
	}
}
.widget-cards__description {
	margin-top: 20px;
}
.widget-cards__items {
	margin: 20px 0;
	@include breakpoint(mw) {
		display: flex;
		margin: 60px 0;
	}
}
.widget-cards__item-wrap {
	flex: 1;
}
.widget-cards__icon {
	margin-bottom: 1rem;
	display: block;

	.icon {
		width: 90px;
		height: 90px;
		fill: $color-primary;
	}
	img {
		height: 90px;
		width: 100%;
		filter: $filter-green;
	}
}
.widget-cards__title {
	font-size: pxtorem(18px);
	font-weight: 700;
	font-family: $font-secondary;
	margin-top: 0;
	margin-bottom: 1rem;
	display: block;
	@include breakpoint(mw) {		
		font-size: pxtorem(26px);
	}
}
.widget-cards__item-description {
	font-size: pxtorem(16px);
	margin-bottom: 1rem;
	padding: 0 1rem;
	font-weight: 400;
}
.widget-cards__item {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
	border-radius: 30px;
	margin-bottom: 20px;
	padding: 20px 1.5rem;
	background-color: $color-invert;
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
	color: $color-base;
	height: 100%;
	text-decoration: none;

	@include breakpoint(mw) {
		border-radius: 40px;
		margin: 0 15px;
	}

	&--gray {
		background-color: $color-light-gray;
	}

	&--blue {
		background-color: $color-secondary;
		color: $color-invert;

		.widget-cards__title {
			margin-top: auto;
			display: flex;
			align-items: center;
			line-height: 1;
			.icon {
				margin-left: 5px;
			}
		}
		.icon {
			fill: $color-invert;
		}
		img {
			filter: $filter-white
		}
	}
	&--transparent {
		background-color: transparent;
		box-shadow: none;
	}
}
a.widget-cards__item {

	&:hover,
	&:focus,
	&:active {
		background: $color-primary;
		color: $color-invert;
		text-decoration: none;
	
		.icon {
			fill: $color-invert;
		}
		img {
			filter: $filter-white;
		}
	}

	&--blue {	
		&:hover,
		&:focus,
		&:active {
			background: $color-invert;
			color: $color-secondary;
		
			.icon {
				fill: $color-secondary;
			}
			img {
				filter: $filter-blue;
			}
		}
	}
}

.widget-cards__button {
	margin-top: auto;
}

/******************************** 
** Cards Grid Styles 
********************************/
.widget-cards--grid {
	.widget-cards__items {
		display: flex;
		flex-wrap: wrap;
		@include breakpoint(mw) {
			flex-wrap: nowrap;
		}
	}
	.widget-cards__item-wrap {
		width: 50%;
		flex: auto;
		margin-bottom: 15px;
	}
	.widget-cards__item {
		margin: 8px;
	}
}

/******************************** 
** Cards RTE Styles 
********************************/
.widget-cards--rte {
	.widget-cards__items {
		flex-wrap: wrap;
	}
	.widget-cards__item-wrap {
		flex: auto;
		margin-bottom: 30px;
		@include breakpoint(mw) {
			width: 33.33%;
		}
	}
	.widget-cards__item {
		padding: 50px 20px;
	}
	.widget-cards__title{
		font-size: pxtorem(21px);
		font-weight: $fontWeight-bold;
		color: $color-secondary;
		margin-bottom: 10px;
		@include breakpoint(mw) {
			font-size: pxtorem(36px);
			font-weight: normal;
		}
	}

	ul {
		@include resetList;
		text-align: left;

		@include breakpoint(mw) {
			columns: 2;
		}
		@include breakpoint(l) {
			columns: 3;
			padding: 0 70px;
		}
	}
	li {
		break-inside: avoid-column;
		margin-bottom: 20px;
		display: flex;

		@include breakpoint(mw) {
			margin-right: 20px;
		}

		&::before {
			content: '';
			display: block;
			transform: rotate(-45deg);
			height: 12px;
			min-width: 24px;
			border-bottom: 3px solid $color-primary;
			border-left: 3px solid $color-primary;
			margin-right: 10px;
		}
	}
}