.interior-top {
    padding: 30px 0;
}

.interior-content,
.interior-rail {
    padding: 20px 0;
}

@media (min-width: $bp-mw) {
    .interior-content,
    .interior-rail {
        padding: 50px 0;
    }

    .page-extra-container {
        display: block;
    }

    .page-extra-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @include x-rem(font-size, 16px);
    }
}