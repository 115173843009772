/* ================================================================
Base styles
================================================================ */

.site-container {

}

.section-container {
    padding:30px 0;

    &--padding-top {
        padding:30px 0 0;
    }
    &--padding-bottom {
        padding:0 0 30px;
    }

    &--white {
        background-color: #ffffff;
    }
    &--gray {
        background-color:#f0f0f0;
    }

    &--padding-small {
        padding: 15px 0;

        &.section-container--padding-top {
            padding:15px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 15px;
        }
    }

    &--padding-medium {
        padding: 30px 0;

        &.section-container--padding-top {
            padding:30px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 30px;
        }
    }

    &--padding-large {
        padding: 50px 0;

        &.section-container--padding-top {
            padding:50px 0 0;
        }
        &.section-container--padding-bottom {
            padding:0 0 50px;
        }
    }

    &--no-padding {
        padding: 0;
    }


  
}

@media (min-width: $bp-mw) {
    .section-container {
        padding:50px 0;

        &--padding-top {
            padding:50px 0 0;
        }
        &--padding-bottom {
            padding:0 0 50px;
        }
    
        &--padding-small {
            padding: 20px 0;

            &.section-container--padding-top {
                padding:20px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 20px;
            }
        }
    
        &--padding-medium {
            padding: 50px 0;

            &.section-container--padding-top {
                padding:50px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 50px;
            }
        }
    
        &--padding-large {
            padding: 70px 0;

            &.section-container--padding-top {
                padding:70px 0 0;
            }
            &.section-container--padding-bottom {
                padding:0 0 70px; 
            }
        }
    
        &--no-padding {
            padding: 0; 
        }
    }
}


.section-container {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    // background color
    .section-container__helper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index:-2;
    }
    &--white {
        background-color: #ffffff;
        &::before {
            background-color: #ffffff;
        }
    }
    &--light-gray {
        background-color:$color-light-gray;
        &::before {
            background-color:$color-light-gray;
        }
    }
    &--gray {
        background-color:$color-light-gray-1;
        &::before {
            background-color:$color-light-gray-1; 
        }
    }
    &--dark-blue {
        color: $color-invert;
        background-color:$color-secondary;
        &::before {
            background-color:$color-secondary;
        }
    }
    &--blue {
        color: $color-invert;
        background-color:$color-blue;
        &::before {
            background-color:$color-blue;
        }
    }
    &--green {
        color: $color-invert;
        background-color:$color-primary;
        &::before {
            background-color:$color-primary;
        }
    }

    // border radius
    &--border-radius-top-left {
        &::before {
            border-top-left-radius: 70px;
        }
    }
    &--border-radius-top-right {
        &::before {
            border-top-right-radius: 70px;
        }
    }
    &--border-radius-bottom-right {
        &::before {
            border-bottom-right-radius: 70px;
        }
    }
    &--border-radius-bottom-left {
        &::before {
            border-bottom-left-radius: 70px;
        }
    }
    &--border-radius-all {
        border-radius: 40px;
        &::before {
            border-radius: 40px;
        }
    }
    &--border-radius-none {
        border-radius: 0;
        &::before {
            border-radius: 0;
        }
    }

    // size
    &--full-width {
        max-width: 100%;
    }

    &--smallest {
        max-width: $container-size-smallest; 
        margin: auto;
    }

    &--xsmall {
        max-width: $container-size-xsmall;
        margin: auto;
    }

    &--small {
        max-width: $container-size-small; 
        margin: auto;       
    }
    &--medium {
        max-width: $container-size;
        margin: auto;       
    }

    // is flyout
    &--flyout {
        position: relative;
        z-index: 2;
        margin: auto $grid-gutter;
        @include breakpoint(mw) {
            margin: auto;
        }
    }
}
.container {
    &--small {
        max-width: $container-size-small;
    }
    &--large {
        max-width: $container-size-large;        
    }
    &--fluid {        
        max-width: 100%;
    }
}

/******************************** 
** Course Info Widget Section 
********************************/
.section-container--course {
    border-radius: 0 0 0 40px;
    @include breakpoint(mw) {
        border-radius: 0 0 0 60px;
    }
}