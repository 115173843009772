/* ================================================================
   Cookie Consent
   ================================================================ */

.cookie-consent {
    background:$color-primary;
    padding:20px 0;
    position: fixed;
    bottom: 0;
    z-index: 9;
    width: 100%;
    text-align: center;
}
.cookie-consent .container {
    @include breakpoint(mw) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.cookie-consent__desc {
    color:$color-invert;
    margin:0 0 1rem;
    @include breakpoint(mw) {
        margin: 0;
    }

    a {
        color: $color-invert;
        font-weight: $fontWeight-bold;
        text-decoration: underline;
        &:hover, 
        &:active, 
        &:focus {
            text-decoration: none;
        }
    }
}
.cookie-consent__submit {
    padding-top: 0;
    padding-bottom: 0;
    @include breakpoint(mw) {
        margin-left: 20px;
    }

    &:hover, 
    &:active, 
    &:focus {
        border-color: $color-invert;
    }
}

/******************************** 
** adding footer bottom padding because of the cookie consent 
********************************/
.cookie-consent ~ .site-footer{
    padding-bottom: 190px;
    @include breakpoint(mw) {
        padding-bottom: 110px;
    }
}