.page-banner {
    padding: 40px 0 10px;
    position: relative;
    z-index: 1;
    text-align: center;
    @include breakpoint(mw) {
        padding: 76px 0 80px;
    }
    &::before {

    }
}
.page-banner__heading {
    margin-bottom: 10px;
    line-height: 1.1;

    @include breakpoint(mw) {
        margin-bottom: 20px;
    }
}
.page-banner__text {
    font-size: pxtorem(20px);

    @include breakpoint(mw) {
        font-size: pxtorem(24px);
    }
}

/******************************** 
**  Page Banner with Graphic
********************************/
.section-container--page-banner-graphic {
    
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
        background-image: url(../images/page-header-graphic.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
        height: 100%;
        width: 80%;
        max-width: 400px;

        @include breakpoint(mw) {
            width: 45%;
            max-width: 945px;
        }
    }
}
.section-container--page-banner-graphic {
    .page-banner {
        text-align: left;
    }

    .page-banner__content {
        padding-bottom: 100px;
    
        @include breakpoint(mw) {
            padding-bottom: 130px;
            max-width: 880px;
        }
    }
}


/******************************** 
**  Page Banner with Graphic and Image
********************************/

.section-container--page-banner-image {
    .page-banner__inner {
        @include breakpoint(mw) {
            display: flex;
            align-items: center;
        }
    }
    
    .page-banner__content {
        flex: 1;
    }
}
.page-banner__figure {
    margin-bottom: 30px;
    margin-left: -#{$grid-gutter-em};
    
    @include breakpoint(mw) {
        width: 800px;
        max-width: 100%;
        margin-right: 0;
        flex: 1;
        margin-bottom: 0;
        margin-right: 30px;
    }
    @include breakpoint($bp-l) {
        margin-right: 60px;
    }
}
.page-banner__img {
    display:block;
    background: no-repeat center center / cover;
    border-radius: 0 50% 50%;
    max-width: 825px;

    &::after { 
        content: ''; 
        display: block; 
        padding-top: percentage(1/1); 
    }
}


/******************************** 
**  Page Banner with Graphic and Image Hero Homepage
********************************/
.section-container--page-banner-image-home {

    &::after {
        background-image: url(../images/page-header-graphic-home.svg);
        right: 0;
        max-height: 275px;

        @include breakpoint(560px) {
            max-height: 100%;
        }

        @include breakpoint(mw) {
            max-height: 100%;
        }
        @include breakpoint($container-size-large) {
            right: calc(  50vw - #{$container-size-large}/2 );
        }
    }

    .page-banner__inner {
        display: flex;
        flex-direction: column-reverse;

        @include breakpoint(768px) {
            flex-direction: row-reverse;
        }
    }
    .page-banner__figure {
        margin-left: auto;
        margin-right: 0;
        width: 100%;
        margin-bottom: 0;

        @include breakpoint(768px) {
            flex: 1 40%;
        }
        @include breakpoint($container-size) {
            flex: 1;
        }
    }
    .page-banner__img {
        border-radius: 0;
        background: no-repeat center / contain;
        max-height: 250px;
        margin: auto;

        @include breakpoint(560px) {
            max-height: 400px;
        }

        @include breakpoint(768px) {
            max-width: 620px;
            margin-right: 0;
            max-height: inherit;
            background: no-repeat 40px center / contain;
        }
        @include breakpoint(1700px) {
            background: no-repeat right center / contain;
        }
    }

    .page-banner__heading {
        @include breakpoint($container-size) {
            font-size: pxtorem(90px);
        }
    }
    .page-banner__content {
        padding-bottom: 30px;
        @include breakpoint(768px) {
            flex: 1 60%;
            padding-bottom: 20px;
        }
        @include breakpoint($container-size) {
            flex: 1;
            padding-bottom: 100px;
        }
    }
    .page-banner__text {
        margin-bottom: 20px;
    }
    @include breakpoint(mw) {
        .page-banner {
            padding: 0;
        }
    }
}