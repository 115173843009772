/* ================================================================

Pagination

   ================================================================ */

.pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 0;
    flex-direction: column;
    @include breakpoint(mw) {
        padding: 40px 0;
        flex-direction: row;
    }

    &.pagination-top {
    }
}

.pagination__results {
    margin-right: 10px;
    margin-bottom: 20px;
    @include breakpoint(mw) {
        margin-bottom: 0;
    }
}

.pagination__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}

.pagination__list-item {
}

.pagination__link {
    text-align: center;
    position: relative;
    color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 20px;
    min-width: 30px;
    min-height: 30px;
    font-weight: $fontWeight-bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    padding: 0 5px;

    svg {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        fill: currentColor;
    }

    &:hover,
    &:focus {
        @extend .pagination__link--active;
        text-decoration: none;
    }
}

.pagination__link--active {
    color: $color-invert;
    background-color: $color-primary;

    svg {
        fill: currentColor;
    }
}
.pagination__link--black {
    border-color: transparent;
    &:hover, 
    &:active, 
    &:focus {
        background-color: transparent;
        color: $color-primary;
    }
}
.pagination__list-item--next,
.pagination__list-item--prev {
    .pagination__link {
        border: none;
        transition: $trans;

        &:hover, 
        &:active, 
        &:focus {
            background-color: transparent;
            color: $color-primary;
            opacity: .8;
        }
    }
}
.pagination__list-item--prev {
    .pagination__link {
        &:hover, 
        &:active, 
        &:focus {
            transform: translateX(-2px);
        }
    }
}
.pagination__list-item--next {
    .pagination__link {
        &:hover, 
        &:active, 
        &:focus {
            transform: translateX(2px);        }
    }
}

.js-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0 0 0;
    border-top: 1px solid #999999;

    a {
        padding: 3px 5px;
        display: block;
        height: 30px;
        width: 22px;
        text-align: Center;
        position: relative;
        color: $color-base;
    }
}
.js-pagination-inner {
    display: flex;
    align-items: center;
}
.js-pagination__previous,
.js-pagination__next {
    padding: 3px 6px;
    display: block;
    height: 30px;
    width: 24px;
    text-align: Center;
    position: relative;
    color: $color-base;
    border: 0;
    background: none;
    cursor: pointer;

    svg {
        width: 20px;
        height: 20px;
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translateY(-50%) translateX(-50%);
        fill: $color-secondary;
    }
    &.jp-disabled {
        display: none;
    }
}
