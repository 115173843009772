/* ================================================================
   Upcoming Courses
================================================================ */

.upcoming-courses {
}
.upcoming-courses__list {
	@include resetList;
	padding: 20px 0;
	margin: 20px 0 0;
	border-top: 1px solid $color-gray;

	@include breakpoint(mw) {
		padding: 60px 0;
		margin: 60px 0 0;
	}
}
.upcoming-courses__item {
	background-color: #fff;
	border-radius: 30px;
	padding: 40px 20px;
	box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
	
	&:not(:last-child) {
		margin-bottom: 20px;
	}

	@include breakpoint(mw) {
		border-radius: 40px;
		padding: 30px 40px;
		display: flex;
		align-items: center;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
}
.upcoming-courses__course {
	flex: 1;
}
.upcoming-courses__course-heading,
h3.upcoming-courses__course-heading  {
	font-size: pxtorem(21px);
	margin-bottom: 10px;
	line-height: 1.2;
	font-weight: $fontWeight-bold;
	color: $color-base;

	@include breakpoint(mw) {
		font-size: pxtorem(32px);
		margin-bottom: 20px;	
		font-weight: $fontWeight-normal;	
	}
	
}
.upcoming-courses__course-details {
	font-size: pxtorem(16px);
	margin-bottom: 20px;
	@include breakpoint(mw) {
		display: flex;
		font-size: pxtorem(20px);
		margin-bottom: 0;
	}

	> * {
		display: flex;
		align-items: center;
		margin-right: 30px;
		margin-bottom: 10px;
		text-align: left;

		@include breakpoint(mw) {
			margin-bottom: 0;
		}

		svg {
			width: 20px;
			height: 20px;
			flex-shrink: 0;
			margin-right: 12px;
			fill: currentColor;
		}
	}
}
.upcoming-courses__course-btn {
	width: 100%;
	@include breakpoint(mw) {
		max-width: 210px;	
	}

	&:not(:last-child){
		margin-bottom: 15px;
		@include breakpoint(mw) {
			margin-right: 20px;
			margin-bottom: 0;
		}
	}
}
.upcoming-courses__btn {
	display: flex;
    margin: 0 auto;
    width: fit-content;
}

/******************************** 
** Course Info Banner Modifiers 
********************************/
.page-banner {
	.upcoming-courses__course-details {
		justify-content: center;
	}
	.upcoming-courses__course-details > * {
		justify-content: center;
	}
}

/******************************** 
** Algolia Search T4T 
********************************/
.algolia-custom {
	.upcoming-courses__course-details > * {
		margin-right: 15px;
		font-size: pxtorem(16px);
		svg {
			margin-right: 5px;
		}
	}
	.upcoming-courses__btns {
		display: flex;
		align-items: center;
		flex-direction: column;
		
		@include breakpoint(1400px) {
			flex-direction: row;
		}
	}
	.upcoming-courses__course-btn {
		padding-left: 15px;
		padding-right: 15px;
		width: 100%;
		max-width: 100%;

		@include breakpoint(mw) {
			width: fit-content;
		}

		&:not(:last-child) {
			margin-right: 0;
			margin-bottom: 10px;

			@include breakpoint(1400px) {
				margin-right: 10px;
				margin-bottom: 0;
			}
		}
	}
}

/******************************** 
** Algolia Courses 
********************************/
.algolia-custom {
	.upcoming-courses__item {
		margin:0 0 30px;
	}
	.upcoming-courses__item--algolia {
		display: block;
		margin:0 0 30px;
	}
	.upcoming-courses__desc {
		border-top: 1px solid $color-gray;
		margin-top: 20px;
		padding: 20px 0;
		@include breakpoint(mw) {
			margin-top: 30px;
			padding: 30px 0;
		}
	}
	.upcoming-courses__item--algolia {
		.upcoming-courses__btns {		
			@include breakpoint(mw) {
				flex-direction: row;
			}
		}
		.upcoming-courses__course-btn {
			min-width: 210px;
			&:first-child {
				@extend .btn--orange;
				color:$color-invert;
			}
			&:not(:last-child) {
				@include breakpoint(mw) {
					margin-right: 30px;
					margin-bottom: 0;
				}
			}
		}
	}
}
