.testimonial-widget {
  background: #f2f2f2;
  box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.2485);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;

  &--bg-white {
background: white;
  }
  &--bg-grey {
    background: #F2F2F2;
  }
}
.testimonial-widget__icon {
  width: 40px;
  height: 28.67px;
}
.testimonial-widget__quote {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 700;
  @include x-rem(font-size, 21px);
  line-height: 28px;
  text-align: center;
  color: #212121;
  margin: 30px 0 20px 0;
}
.testimonial-widget__name {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 700;
  @include x-rem(font-size, 14px);
  line-height: 12px;
  text-align: center;
  letter-spacing: 1.75px;
  text-transform: uppercase;
  color: #212121;
}

@include breakpoint($bp-m) {
  .testimonial-widget {
    padding: 76px 60px;
  }
  .testimonial-widget__icon {
    width: 60px;
    height: 43px;
  }
  .testimonial-widget__quote {
    @include x-rem(font-size, 36px);
    font-weight: 500;
    line-height: 42px;
    margin: 15px 0 30px 0;
  }
}
