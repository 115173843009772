.clients-widget-container {
}
.clients-widget__header {
  margin-bottom: 30px;
}

.clients-widget__button {
  display: none;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding-right: 65px;
  padding-left: 65px;
  margin: 30px auto;
}
.clients-widget {
}
.clients-widget__list {
  @include resetList;
  display: flex;
  align-items: center;
  column-gap: 40px;
  row-gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
}
.clients-widget__list-item {
  flex: 1 1 24%;
  max-width: 24%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients-widget__list-item-img {
}
@include breakpoint($bp-m) {
  .clients-widget__button {
  display: flex;
}
  .clients-widget__list {
  gap: 52px;
}
.clients-widget__list-item {
  flex: 1 1 12.5%;
  max-width: 12.5%;
}
}
@include breakpoint($bp-mw) {
.clients-widget__list-item {
  flex: 1 1 7.5%;
  max-width: 7.5%;
}
}

@include breakpoint($bp-l) {
  .clients-widget__header {
    margin-bottom: 88px;
  }
  .clients-widget__button {
    margin: 70px auto;
  }
}
