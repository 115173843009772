.location-listing-widget {
}
.location-listing-widget__accordion {
    width: 100%;
}
.accordion-toggle {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 30px;
    border: none;
	cursor: pointer;
	background-color: transparent;
    letter-spacing: -0.25px;
    color: $color-base;
    border-bottom: 2px solid $color-gray;
    width: 100%;
    display: flex;
	padding: 25px 0;
    position: relative;

	@include breakpoint(mw) {
		font-size: pxtorem(48px);
		padding: 30px 0;
	}
}

.accordion-toggle__icon {
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 0;
    height: 22px;
    width: 22px;

	@include breakpoint(mw) {
		height: 40px;
		width: 40px;
	}
}
.accordion-toggle .accordion-toggle__icon--minus,
.accordion-toggle.active .accordion-toggle__icon--plus {
    visibility: hidden;
    opacity: 0;
}
.accordion-toggle.active .accordion-toggle__icon--minus,
.accordion-toggle .accordion-toggle__icon--plus {
    visibility: visible;
    opacity: 1;
}

.location-listing-widget__accordion-target {
}
.location-listing-widget__locations {
	margin-top: 20px;

	@include breakpoint(mw) {
		margin-top: 30px;
	}
}

.location-listing-widget__location {
    display: flex;
    flex-direction: column;
	@include breakpoint(mw) {
		flex-direction: row;
	}
}
.location-listing-widget__location:first-of-type {
    padding-bottom: 40px;
}
.location-listing-widget__location {
    padding: 40px 0;
	border-bottom: 2px solid $color-gray;

	&:not(:last-of-type) {
	}
}
.location-listing-widget__heading {
	margin-bottom: 0;
}
.location-listing-widget__location-title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    color: #212121;
    margin-bottom: 20px;
	font-size: pxtorem(21px);
	@include breakpoint(mw) {
		font-size: pxtorem(26px);
		width: 25%;
	}
}
.location-listing-widget__location-info {
	@include breakpoint(mw) {
		width: 75%;
	}
}
.location-listing-widget__location-info-list {
    @include resetList;
    display: flex;
    flex-direction: column;

	&:not(:last-child) {
		margin-bottom: 40px;
	}

	@include breakpoint(mw) {
		flex-direction: row;
	}
}
.location-listing-widget__location-info-col {
	@include breakpoint(mw) {
		padding-right: 30px;
		width: 33.33%;
	}
}
.location-listing-widget__location-info-list-item {
	margin-bottom: 20px;
}
.location-listing-widget__h3 {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.4;
    color: #212121;
}
.location-listing-widget__link {
	word-break: break-all
}

.location-listing-widget__social-list {
    display: flex;
    flex-wrap: wrap;
	& > * {
		width: 50%;
	}

}
.location-listing-widget__social-list a {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    display: flex;
    align-items: center;
    color: $color-primary;
	margin-bottom: $grid-gutter;
	margin-right: 5px;
	text-decoration: none;
	&:hover, 
	&:active, 
	&:focus {
		opacity: .8;
	}
}

.location-listing-widget__social-list svg {
    width: 22px;
    height: 22px;
    fill: currentColor;
	margin-right: 10px;
}
/******************************** 
** search form 
********************************/
.location-listing-widget__form {
	margin-bottom: 40px;
	@include breakpoint(mw) {
		display: flex;
		margin-bottom: 90px;
	}
}
.location-listing-widget__form-input-wrap {
	width: 100%;
	margin-right: 20px;
}
.location-listing-widget__form-input {
	border-radius: 50px;
    width: 100%;
}
.location-listing-widget__form-btn {
	margin-top: 15px;
	width: 100%;
	@include breakpoint(mw) {
		margin-top: 0;
		width: auto;
	}
}