.stats-widget-container {
}
.stats-widget {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.stats-widget__header {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  @include x-rem(font-size, 32px);
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.5px;
}
.stats-widget__description {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 300;
  @include x-rem(font-size, 20px);
  line-height: 30px;
  text-align: center;
}
.stats-widget__button {
  width: fit-content;
  margin: auto;
}
.stats-widget__list {
  @include resetList;
  display: flex;
  flex-wrap: wrap;
  row-gap: 40px;
}
.stats-widget__list-item {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.stats-widget__list-item-stat {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 300;
  @include x-rem(font-size, 72px);
  line-height: 70px;
  text-align: center;
  letter-spacing: -1px;
}
.stats-widget__list-item-label {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 700;
  @include x-rem(font-size, 20px);
  line-height: 28px;
  text-align: center;

}

@include breakpoint($bp-sw) {
  .stats-widget__list-item {
    flex: 1 1 50%;
  }
}
@include breakpoint($bp-mw) {
  .stats-widget {
    gap: 30px;
  }
  .stats-widget__header {
    @include x-rem(font-size, 60px);
    line-height: 70px;
  }

  .stats-widget__list {
    margin-top: 30px;
  }
}

@include breakpoint($bp-l) {
  .stats-widget__list-item {
    flex: 1 1 20%;
  }
}
