$timeline-bp: 66.25em; // 768px
$timeline-bp-l: 66.25em; // 1060px
// theme / dots variables
$lime-green: #9ed8b3;
$ocean-blue: #005d7d;
$grass-green: #008065;
$night-blue: #003146;
$forest-green: #005a46;
$pumpkin-orange: #e55300;

// timeline tabs / buttons
.timeline-buttons__title {
  @include x-rem(margin-bottom, 10px);
  @include x-rem(font-size, 12px);
  @include x-rem(line-height, 34px);
  letter-spacing: 5px;
  text-align: center;
  font-weight: $fontWeight-bold;
  color: $color-secondary;
  @include breakpoint($timeline-bp) {
    @include x-rem(margin-bottom, 40px);
    @include x-rem(font-size, 18px);
  }
}

.timeline-btns {
  @include resetList;
  display: flex;
  @include x-rem(gap, 30px);
  overflow-x: scroll;

  @include breakpoint($timeline-bp) {
    justify-content: center;
  }
}
.timeline-btns__button {
  @include resetBtn;
  border-radius: 100px;
  border: 2px solid $color-light-gray-2;
  background: $body-bg;
  @include x-rem(padding, 1px 17px);
  font-weight: $fontWeight-semibold;
  color: $color-secondary;
  @include x-rem(font-size, 16px);
  @include x-rem(line-height, 34px);
  &.active,
  &:hover,
  &:focus {
    border-color: $color-secondary;
  }
  @include breakpoint($timeline-bp) {
    @include x-rem(font-size, 26px);
  }
}
.timeline-content__container {
  @include x-rem(padding-bottom, 50px);
  @include breakpoint($timeline-bp) {
    @include x-rem(padding-bottom, 200px);
  }
}

.timeline-content__tab-content {
  position: relative;
  @include x-rem(padding-left, 40px);

  @include breakpoint($timeline-bp-l) {
    padding-left: 0;
  }
}
// vertical line
.timeline-content__vertical-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 2px;
  background: $color-base;
  z-index: -1;
  opacity: 0;
  transition: all 0.7s ease-in;
  @include breakpoint($timeline-bp-l) {
    padding-left: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
// bottom left graphic
.timeline-content .section-container--page-banner-graphic {
  &::after {
    content: none;
    right: initial;
    left: 0;
    transform: scaleX(-1);
    z-index: -1;
  }
  @include breakpoint($timeline-bp) {
    &::after {
      content: "";
    }
  }
}

// timeline content / items

.timeline-content__title {
  text-align: center;
  margin: 0;
  background: $body-bg;
  position: relative;
  z-index: 2;

  &:before {
    content: "";
    position: absolute;
    top: -31px; 
    left: -40px;
    width: 2px;
    height: 32px;
    background: $color-base;
    z-index: -1;
    
  }
  @include x-rem(padding-bottom, 50px);
  @include breakpoint($timeline-bp) {
    @include x-rem(padding, 30px 0);
    &:before {
      height: 50px;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.timeline-listing1 {
  @include resetList;
  display: flex;
  flex-direction: column;
  @include x-rem(gap, 50px);
  @include x-rem(padding-top, 5 0px);
  @include breakpoint($timeline-bp) {
    @include x-rem(gap, 215px);
    @include x-rem(padding-top, 70px);
  }
}
.timeline-listing1__item {
  display: flex;
  flex-direction: column;

  @include breakpoint($timeline-bp) {
    flex-direction: row;
    align-items: center;
    @include x-rem(gap, 110px);
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}
.timeline-listing1__imgbox,
.timeline-listing1__contentbox {
  width: 100%;

  @include breakpoint($timeline-bp) {
    @include x-rem(max-width, 450px);
    opacity: 0;
  }
}
.timeline-listing1__imgbox {
  flex: 1;
  position: relative;
  img {
    border-radius: 20px;
  }
}
// vertical line dots / color variants
.timeline-listing1__bullet {
  @include x-rem(height, 20px);
  @include x-rem(width, 20px);
  border-radius: 50%;
  opacity: 0;
  transition: all 0.7s 0.3s ease-in-out;
  border: 2px solid transparent;
  position: absolute;
  z-index: 15;
  top: 0;
  left: -49px;
  border: 2px solid transparent;

  &--pumpkinorange {
    background: $pumpkin-orange;
  }
  &--limegreen {
    background: $lime-green;
  }

  &--forestgreen {
    background: $forest-green;
  }
  &--grassgreen {
    background: $grass-green;
  }
  &--nightblue {
    background: $night-blue;
  }
  &--oceanblue {
    background: $ocean-blue;
  }
  &.active-dot {
    opacity: 1;
    border: 2px solid $color-secondary;
  }
  @include breakpoint($timeline-bp-l) {
    @include x-rem(height, 30px);
    @include x-rem(width, 30px);
    left: initial;
    right: -70px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.timeline-listing1__item:nth-child(even) {
  .timeline-listing1__bullet {
    @include breakpoint($timeline-bp-l) {
      left: -70px;
    }
  }
}
.timeline-listing1__imgbox {
  &:after {
    left: -49px;
    top: 0;
    opacity: 0;
  }
}
.timeline-listing1__item:nth-child(even) {
  .timeline-listing1__imgbox::after {
    @include breakpoint($timeline-bp-l) {
      left: -70px;
    }
  }
}

.timeline-listing1__imgbox.active-image {
  &::after {
    opacity: 1;
    border: 2px solid $color-secondary;
  }
}

.timeline-listing1__year {
  @include x-rem(font-size, 24px);
  font-weight: $fontWeight-medium;
  color: $color-secondary;
  @include x-rem(margin, 30px 0);

  @include breakpoint($timeline-bp) {
    border-radius: 20px;
    margin: 0;
    width: fit-content;
    height: fit-content;
    background: $body-bg;
    @include x-rem(padding, 20px);
    @include x-rem(font-size, 150px);
    font-weight: $fontWeight-extrabold;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 91%;
    bottom: 0px;
    line-height: 0.7;
  }
}
.timeline-listing1__contentbox {
  border-radius: 20px;
  @include x-rem(padding, 30px);
  @include breakpoint($timeline-bp) {
    @include x-rem(padding, 50px 30px);
  }
}
.timeline-listing1__title {
  @extend .h4;
  text-align: center;
  font-weight: $fontWeight-semibold;
  @include breakpoint($timeline-bp) {
    @include x-rem(margin-bottom, 40px);
  }
}
.timeline-next-btn {
  display: flex;
  align-items: center;
  @include x-rem(gap, 10px);
  @include x-rem(padding, 10px 50px);
  svg {
    fill: $color-invert;
    @include x-rem(width, 20px);
    @include x-rem(height, 12.5px);
    transition: $trans;
  }
  &:hover,
  &:focus {
    svg {
      transform: translateX(20px);
    }
  }
}
.timeline-next-btn {
  margin: 0 auto;
  @include x-rem(margin-top, 20px);
  display: block;
  width: fit-content;
  @include breakpoint($timeline-bp) {
    @include x-rem(margin-top, 195px);
  }
  &.inactive {
    opacity: 0.5;
    pointer-events: none;
  }
}

.timeline-box {
  // themes
  &--limegreen {
    .timeline-btns__button {
      &.active,
      &:hover,
      &:focus {
        background: $lime-green;
      }
    }
    .timeline-listing1__contentbox {
      background: $lime-green;
      & > *,
      a {
        color: $color-secondary;
      }
    }
    .timeline-listing1__desc {
    }
  }

  &--oceanblue {
    .timeline-btns__button {
      &.active,
      &:hover,
      &:focus {
        background: $ocean-blue;
        color: $color-invert;
      }
    }
    .timeline-listing1__contentbox {
      background: $ocean-blue;
      & > *,
      a {
        color: $color-invert;
      }
    }
  }
  &--grassgreen {
    .timeline-btns__button {
      &.active,
      &:hover,
      &:focus {
        background: $grass-green;
        color: $color-invert;
      }
    }
    .timeline-listing1__contentbox {
      background: $grass-green;
      & > *,
      a {
        color: $color-invert;
      }
    }
  }
  &--nightblue {
    .timeline-btns__button {
      &.active,
      &:hover,
      &:focus {
        background: $night-blue;
        color: $color-invert;
      }
    }
    .timeline-listing1__contentbox {
      background: $night-blue;
      & > *,
      a {
        color: $color-invert;
      }
    }
  }
  &--forestgreen {
    .timeline-btn__button {
      &.active,
      &:hover,
      &:focus {
        background: $forest-green;
        color: $color-invert;
      }
    }

    .timeline-listing1__contentbox {
      background: $forest-green;
      & > *,
      a {
        color: $color-invert;
      }
    }
  }
}

.timeline-listing1__contentbox {
  &--limegreen {
    background: $lime-green;
    & > *,
    a {
      color: $night-blue;
    }
  }
  &--oceanblue {
    background: $ocean-blue;
    @include whitenText;
  }
  &--grassgreen {
    background: $grass-green;
    @include whitenText;
  }
  &--nightblue {
    background: $night-blue;
    @include whitenText;
  }
  &--forestgreen {
    background: $forest-green;
    @include whitenText;
  }
  &--pumpkinorange {
    background: $pumpkin-orange;
  }
}
.timeline-btns__button {
  &--pumpkinorange {
    &.active,
    &:hover,
    &:focus {
      background: $pumpkin-orange;
    }
  }
  &--limegreen {
    &.active,
    &:hover,
    &:focus {
      background: $lime-green;
    }
  }
  &--oceanblue {
    &.active,
    &:hover,
    &:focus {
      background: $ocean-blue;
      color: $color-invert;
    }
  }
  &--grassgreen {
    &.active,
    &:hover,
    &:focus {
      background: $grass-green;
      color: $color-invert;
    }
  }
  &--nightblue {
    &.active,
    &:hover,
    &:focus {
      background: $night-blue;
      color: $color-invert;
    }
  }
  &--forestgreen {
    &.active,
    &:hover,
    &:focus {
      background: $forest-green;
      color: $color-invert;
    }
  }
}

// scroll animations fade-in-bottom
.active-timeline {
  animation: fade-in-bottom 0.5s ease-in-out both;

  @include breakpoint($timeline-bp) {
    animation: none;
    .timeline-listing1__imgbox {
      animation: slide-in-left 0.5s ease-in-out both;
    }
    .timeline-listing1__contentbox {
      animation: slide-in-right 0.5s ease-in-out both;
    }
    &:nth-child(even) {
      .timeline-listing1__imgbox {
        animation: slide-in-right 0.5s ease-in-out both;
      }
      .timeline-listing1__contentbox {
        animation: slide-in-left 0.5s ease-in-out both;
      }
    }
  }
}
