.widget-link-list {
    text-align: center;

    &__heading {
        max-width: 60rem;
        color: $color-secondary;
        margin: 0 auto;
    }

    &__description {
        max-width: 72rem;
        font-size: pxtorem(20px);
        margin: 1rem auto;
    }

    &__items {
        @include flexBox($flexalign: stretch);
        margin: 1rem 0;
    }

    &__item {
      text-align: left;
      padding: 2rem;
      width: 100%;
      background: $color-invert;
      border-radius: 20px;
      margin: 1rem;

      @include breakpoint(m) {
        width: calc( 100% * (1/2) - 2rem);
        padding: 1.5rem;
      }

      @include breakpoint(xl) {
        width: calc( 100% * (1/4) - 2rem);
        padding: 1.5rem;
      }
    }

    &__title {
        font-size: pxtorem(21px);
        font-weight: 700;
        font-family: $font-secondary;
        line-height: 1.5;

        @include breakpoint(m) {
            font-size: pxtorem(26px);
        }

    }

    &__list {
      padding: 0;
      list-style: none;

      li {
        padding: .5rem 0;
        a,
        span {
          font-weight: 700;
          font-size: pxtorem(19px);
        }
      }
    }
}