

$transition: all ease .5s;
$heading-wrapper: 54rem;

%item-link {
    border-radius: 40px;
    width: 100%;
    max-width: pxtorem(330px);
    margin: 1rem .5rem;
    padding: 2rem;
}

/*
    Global widget styling
*/

.global-widget {
    text-align: center;

    h2 {
        margin: 0 auto;
        max-width: 800px;
    }
    .widget-cards__description {
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
    }

    > p {
        font-size: pxtorem(20px);
        margin: 1rem auto;

        @include breakpoint(m) {
            font-size: pxtorem(24px);
        }
    }

    .btn {
        display: inline-block;
        padding: 10px 28px;
    }
}

/* adds the padding to a wrapper div we can add to the RTE when next to a quote on the right */
.content-left-of-quote {
    margin-bottom: 40px;
    
    @include breakpoint(mw) {
        padding-top: 2rem;
        padding-right: 6.75rem;
        margin-bottom: 0;
    }
}

/* get started RTE on left side of 2 column section – SLII program detail page for example */
.get-started-left {
    text-align: center;
    margin-bottom: 3em;

    @include breakpoint(mw) {        
        padding-top: 2rem;
        padding-right: 6.75rem;
        text-align: left;
        margin-bottom: 0;
    }

    .btn {
        margin-top: 1.5rem;
        min-width: 16rem;
        width: 100%;

        @include breakpoint(mw) {
            width: auto;
            &:not(:last-child) {
                margin-right: 1.5rem;
            }
        }
    }
}
