/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$body-bg: #fff;
$color-base: #000;
$color-invert: #fff;
$color-primary: #008065;
$color-primary-dark: #005A46;
$color-secondary: #003146;
$color-blue: #005D7D ;
$color-gray: #E0E0E0;
$color-light-gray: #F5F5F5;
$color-light-gray-1: #D1D1CE;
$color-light-gray-2: #ccc;
$color-dark-gray: #A9A9AD;
$color-orange: #E55300;



// Typography
$font-primary: 'Noto Sans', sans-serif;
$font-secondary: 'Barlow', sans-serif;
$font-heading: $font-secondary;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$fontWeight-extrabold: 800;

$typography-margin-bottom: .3em;


// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 71.25em; // 1140px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px

// Transitions
$trans-duration: 0.18s;
$trans-ease: ease-in-out;
$trans: all $trans-duration $trans-ease;

// Grid
$container-size: 90.625rem; // 1450px
$container-size-smallest: 31.25em; // 500px
$container-size-xsmall: 42.5em; // 680px
$container-size-small: 57.5em; // 920px
$container-size-medium-m: 65.625em; // 1050px
$container-size-medium: 72.5em; // 1160px
$container-size-large: 117.5rem; // 1880px
$grid-gutter: 20px;
$grid-gutter-em: 1.25em; // Changing grid gutter to em causes a compiling error :/

$filter-green: brightness(0) saturate(100%) invert(29%) sepia(69%) saturate(1723%) hue-rotate(143deg) brightness(94%) contrast(102%);
$filter-light-green: brightness(0) saturate(100%) invert(92%) sepia(18%) saturate(591%) hue-rotate(76deg) brightness(91%) contrast(85%);
$filter-blue: brightness(0) saturate(100%) invert(15%) sepia(29%) saturate(3246%) hue-rotate(167deg) brightness(92%) contrast(106%);
$filter-white: brightness(0) saturate(100%) invert(99%) sepia(0%) saturate(7423%) hue-rotate(92deg) brightness(106%) contrast(100%);