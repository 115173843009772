.trending-list-widget {
}
.trending-list-widget a {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}
.trending-list-widget__top-section {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-bottom: 50px;
  width: 100%;
}
.trending-list-widget__title {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  @include x-rem(font-size, 32px);
  line-height: 38px;
  letter-spacing: -0.5px;
  color: #ffffff;
  margin: 0;
}
.trending-list-widget__top-section a {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
  @include x-rem(font-size, 19px);
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.316667px;
  color: #9ed8b3;
}
.trending-list-widget__top-section a svg {
  height: 16px;
  width: 16px;
  fill: currentColor;
}
.trending-list-widget-list {
  @include resetList;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
}
.trending-list-widget-list-item {
  display: flex;
  align-items: flex-start;
  column-gap: 15px;
  flex: 1 1 100%;
}
.trending-list-widget-list-item__title {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  @include x-rem(font-size, 21px);
  line-height: 28px;
  text-align: center;
  color: #a9c3cb;
  margin: 0;
}
.trending-list-widget-list-item__content {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  height: 100%;
}
.trending-list-widget-list-item__content p {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 700;
  @include x-rem(font-size, 18px);
  line-height: 24px;
  color: #ffffff;
}
.trending-list-widget-list-item__content a {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
  @include x-rem(font-size, 16px);
  line-height: 28px;
  color: #9ed8b3;
  text-decoration: none;
}

@include breakpoint($bp-sw) {
  .trending-list-widget__top-section a {
    column-gap: 4px;
    text-decoration: none;
  }
  .trending-list-widget-list-item {
    flex: 1 1 47%;
  }
}

@include breakpoint($bp-m) {
  .trending-list-widget__top-section {
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 65px;
    justify-content: space-between;
  }
  .trending-list-widget__title {
    @include x-rem(font-size, 60px);
    line-height: 70px;
    letter-spacing: -1px;
  }

  .trending-list-widget-list-item__title {
    @include x-rem(font-size, 36px);
    line-height: 42px;
  }

  .trending-list-widget-list-item__content p {
    @include x-rem(font-size, 20px);
    line-height: 28px;
  }
  .trending-list-widget-list-item__content a {
    @include x-rem(font-size, 16px);
    line-height: 28px;
  }
}

@include breakpoint($bp-l) {
  .trending-list-widget-list-item {
    flex: 1 1 0%;
  }
}
