/* ================================================================
   Typography Styles
   ================================================================ */


   h1, .h1, h2, .h2, h3, .h3 {
	margin:0 0 $typography-margin-bottom;
	font-family:$font-heading;
	font-weight: $fontWeight-medium;
}

h1, .h1 { 
	font-size: pxtorem(38px);
	line-height: pxtorem(38px);
	@include breakpoint(mw) {
		font-size: pxtorem(60px);
		line-height: pxtorem(70px);
	}
}
h2, .h2 {
	font-size: pxtorem(32px);
	line-height: pxtorem(38px);
	@include breakpoint(mw) {
		font-size: 3rem;
		line-height: 3.5rem;
	}
}
h3, .h3 { 
	font-size: pxtorem(24px);
	line-height: pxtorem(30px);
	@include breakpoint(mw) {
		font-size: 2.5rem;
		line-height: 3.0rem;
	}
}
h4, .h4 { 
	font-size: pxtorem(21px);
	font-family: $font-secondary; 
	margin: 0 0 0.3em;
	font-weight: $fontWeight-normal;
	@include breakpoint(mw) {
		font-size: 1.625rem;
	}
}
h5, .h5 {
	font-size: pxtorem(18px);
	font-family: $font-secondary; 
	margin: 0 0 0.3em;
	font-weight: $fontWeight-normal;
	@include breakpoint(mw) {
		font-size: 1.5rem;
	}
}
h6, .h6 { 
	font-size: 1rem;
	font-weight: $fontWeight-bold;
	text-transform: uppercase;
	font-family: $font-secondary; 
	margin: 0 0 0.3em;
}

.section-container--white, 
.section-container--light-gray {
	h1,
	h2,
	h3 {
		color: $color-secondary;
	}
 }

a {
	color:$color-primary;
	text-decoration:underline;
	transition: $trans;

	&:hover, &:focus { text-decoration:none; }
}

p { margin: 0 0 0.7em; }

// Very simple blockquote styling
blockquote {
	margin:1em 1.5em; padding-left:1.5em;
	border-left:5px solid hsl(0, 0%, 85%);
}

.smallest { font-size:0.8em; }
.smaller { font-size:0.9em; }
.larger {
	font-size: pxtorem(20px);
	@include breakpoint(mw) {
		font-size: pxtorem(24px);
	}
}
.largest { font-size:1.2em; }

.bold { font-weight:bold; }
.italic { font-style:italic; }
.strike { text-decoration:line-through; }

.nowrap { white-space:nowrap; }
.important { color:red; font-weight:bold; text-transform:uppercase; }

.title {
	@include fluid-type($bp-s, $bp-mw, 20px, 30px);
	font-weight: $fontWeight-semibold;
	margin: 0 0 5px;
}

.align-center {
	text-align: center;
}

.text-dark-blue {
	color: $color-secondary;
}