.site-header {
    position: relative;
    padding: 0;
    background:#fff;
}

.header-col {
    position: static;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(mw) {}
}

.header-top {
    display: none;
    @include breakpoint(mw) {
        display: block;
        display: flex; 
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px 0;
        background-color: $color-light-gray;
    }
}

.header-logo {
    margin: 0;
    font-size: 0;
    max-width: 160px;
    padding: 40px 10px 30px;
    transition: $trans;

    @include breakpoint(mw) {
        padding: 10px;
        max-width: 160px;
    }

    @include breakpoint(1499px) {
        padding: 58px 10px 58px 0;
        max-width: 280px;
    }

    a {
        display: inline-block;
    }
}
.brand {
    img {
        min-height: 25px;
        width:100%;
        @include breakpoint(mw) {
            min-height: 44px;
        }
    }
}

.culture-selector {
    position: relative;
    margin:0 20px 0 0;
}
.culture-selector__button {
    @extend .button !optional;
    display:flex;
    align-items: center;

    &:after {
        content:'';
        border-top:5px solid $color-invert;
        border-left:4px solid transparent;
        border-right:4px solid transparent;
        margin:0 0 0 7px;
    }
}
.culture-selector__list {
    @include resetList;
    position: absolute;
    top:100%;
    left:0;
    border:1px solid $color-primary;
    background:#fff;
    z-index: 1001;
    display:none;
}
.culture-selector__item {

}
.culture-selector__link {
    text-decoration: none;
    display: block;
    padding:7px 15px;

    &:hover,
    &:focus {
        background:$color-primary;
        color: $color-invert;
        text-decoration: none;
    }
}

.header-search-trigger {
    @include resetBtn;
    display: block;
    width: 40px;
    height: 40px;
    font-size: 0;
    color: $color-primary;
    flex: 0 0 auto;
    background-color: $color-invert;
    border-radius: 50%;

    &:hover,
    &:focus {
        color: $color-secondary;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
        margin-right: 0 !important;
        top: 0 !important;
    }
}
.header-search-wrap {
    background-color: $color-light-gray;
    padding: 20px;
    display: block;
    @include breakpoint(mw) {
        display: none;
    }
}
.header-search {
    position: relative;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    display: block;
    z-index: 0;
}

.header-search-input {
    display: block;
    width: 100%;
    height: 40px;
    background: none;
    padding: 10px $grid-gutter-em 10px 45px;
    color: $color-base;
    background: $color-invert;
    border-radius: 30px;
    font-family: $font-primary;
    border-color: transparent;

    @include placeholder {
      color: $color-base;
      opacity: 1;
    }
    &:hover, 
    &:active, 
    &:focus {
        border-color: $color-primary
    }
}

.header-search-submit {
    @include resetBtn;
    position: absolute;
    top: 22px;
    left: $grid-gutter-em;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    color: $color-primary;

    &:hover,
    &:focus {
        opacity: 0.75;
    }

    svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
}

.header-bottom {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    border-top: 1px solid $color-gray;
    @include breakpoint(mw) {
        border-top: none;
    }
}
#js-autocomplete {
    ul {
        @include resetList;
        padding-top: 10px;
        padding-left: 8px;
    }
    em {
        font-weight: $fontWeight-bold;
        font-style: normal;
    }
}

@media (min-width: $bp-mw) {
    .header-col {
        overflow: hidden;
    }

    .header-search,
    .header-search[data-expander-target] {
        position: relative;
        display: block;
        max-width: $container-size-large;
        margin: auto;
    }

    .header-bottom,
    .header-bottom[data-expander-target] {
        position: static;
        display: block;
        top:auto;
        width:auto;
        z-index:auto;
    }
    .header-search-wrap {
        position: absolute;
        top: 60px;
        width: 100%;
        left: 0;
        z-index: 1;
    }
}

.build-journey {
    &--mobile {
        margin: 20px auto;
        display: flex;
        width: fit-content;
        @include breakpoint(mw) {
            display: none;
        }
    }

    &--desktop {
        display: none;

        @include breakpoint(mw) {
            font-size: pxtorem(14px);
            padding: 10px;
            display: block;
            transform: $trans;
        }
        @include breakpoint(1499px) {
            font-size: pxtorem(19px);
            padding: 18px 20px;
        }
    }
}
.utility-nav {
    ul {
        @include resetList;
        font-weight: $fontWeight-bold;
        text-transform: uppercase;
        font-size: pxtorem(14px);
    }
    svg {
        fill: $color-primary;
        width: 17px;
        height: 17px;
        position: relative;
        top: 3px;
        margin-right: 3px;
    }

    a {
        text-decoration: none;
    }

}
.utility-nav--desktop {
    ul {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    li {
        &:not(:last-child) {
            margin-right: 40px;
        }
    }
}
.utility-nav--mobile {
    text-align: center;
    margin: 20px;

    @include breakpoint(mw) {
        display: none;
    }
    
    ul {
        display: flex;
        flex-direction: column;
    }
    a {
        display: block;
        padding: 10px 0;
    }
}