/* ================================================================
   Book Listing Carousel
================================================================ */

.book-listing-carousel {
	
}
.book-listing-carousel__header {
   @include breakpoint(mw) {
      display: flex;
      align-items: center;
   }
}
.book-listing-carousel__heading {
   @include breakpoint(mw) {
      margin-bottom: 0;
   }
}
.book-listing-carousel__explore-link {
   margin-left: auto;
   font-weight: $fontWeight-bold;
   display: flex;
   align-items: center;

   svg {
      fill: $color-primary;
      width: 16px;
      height: 16px;
      margin-left: 4px;
   }
}
.book-listing-carousel__container-wrapper {
   position: relative;
   padding-top: 40px;

   @include breakpoint(mw) {
      padding-top: 60px;
   }
}
.book-listing-carousel__container {
   padding: 0 0 0 $grid-gutter;
   width: 100vw;
   position: relative;
   left: 50%;
   right: 50%;
   margin-left: -50vw;
   margin-right: -50vw;

   @include breakpoint(mw) {
      width: auto;
      position: static;
      margin-left: 0;
      margin-right: 0;
      padding: 0 90px;
   }
   @include breakpoint(1680px) {
      padding: 0 0;
   }
}

.book-listing-carousel__item {
   padding: 10px;
   display: flex !important;
   flex-direction: column;

   @include breakpoint(mw) {
      margin: 0 15px;
   }
   
}

.book-listing-carousel__item {

}
.book-listing-carousel__card {
   display: flex;
   flex-direction: column;
   height: 100%;
   border-radius: 40px;
   box-shadow: 0 2px 8px rgba(51, 51, 51, 0.25);
   padding: 60px;
   text-align: center;
   background-color: #fff;
}
.book-listing-carousel__figure {
}
.book-listing-carousel__img {
   display:block;
   background: no-repeat center center / contain;
   max-width: 150px;
   margin: auto;
   border: 1px solid $color-gray;
   &::after { 
       content: ''; 
       display: block; 
       padding-top: percentage(210/150); 
   }
}
.book-listing-carousel__card-heading {
   font-size: pxtorem(18px);
   font-weight: $fontWeight-bold;
   font-family: $font-secondary;
   line-height: 1.2;
   margin-bottom: 10px;

   @include breakpoint(mw) {
      font-size: pxtorem(20px);
   }
}
.book-listing-carousel__card-header {
   padding-bottom: 30px;
   border-bottom: 2px solid $color-gray;
   margin-bottom: 30px;
}
.book-listing-carousel__card-body {
   display: flex;
   flex-direction: column;
   height: inherit;
   justify-content: center;
}
.book-listing-carousel__card-text {
   font-size: pxtorem(14px);
   text-transform: uppercase;
   max-width: 200px;
   margin: 0 auto 15px;
}
.book-listing-carousel__card-btn {
   margin: 0 auto 0;
   width: fit-content;
}

/******************************** 
** Slider Nav 
********************************/
.book-listing-carousel__btn {
   @extend .resources-listing-slider__btn; 

   &--prev {
      @extend .resources-listing-slider__btn--prev;

      @include breakpoint(1680px) {
         left: -120px;
      }
   }

   &--next {
      @extend .resources-listing-slider__btn--next;

      @include breakpoint(1680px) {
         right: -120px;
      }
   }
}
.book-listing-carousel__dots {   
   @extend .resources-listing-slider__dots;
}
.book-listing-carousel {
   .slick-track
   {
       display: flex !important;
   }
   .slick-slide
   {
       height: inherit !important;
   }

}