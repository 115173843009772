.site-footer {
    background: $color-secondary;
    color: $color-invert;
    padding: 40px 0 35px;
    margin:0;
    @include breakpoint(mw) {
        padding: 90px 0 58px;
    }
}

/******************************** 
** Footer Top 
********************************/
.footer-top {
    text-align: center;
    @include breakpoint(mw) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.footer-logo {
    a {
        display: inline-block;
    }
    img {
        width: 160px;
        max-width: 100%;

        @include breakpoint(mw) {
            width: 211px;

        }
    }
}

.footer-cta {

    @include breakpoint(mw) {
        display: flex;
        align-items: center;
    }
}
.footer-cta__heading {
    font-size: pxtorem(18px);
    line-height: 1;
    margin: 30px auto 20px;

    @include breakpoint(mw) {
        font-size: pxtorem(36px);
        margin-right: 35px;
        margin-bottom: 0;
        margin-top: 0;
    }
}
.footer-cta .btn {
    width: 266px;
    max-width: 100%;

    @include breakpoint(mw) {
        width: 210px;
    }
}

/******************************** 
** Footer Middle 
********************************/
.footer-middle {
    margin: 35px 0 40px;

    @include breakpoint(m) {
        border-top: 2px solid $color-blue;
        border-bottom: 2px solid $color-blue;
        padding: 50px 0;
        margin: 46px 0 60px;
    }
}
.footer-heading {
    font-size: pxtorem(16px);
    line-height: pxtorem(16px);
    font-weight: $fontWeight-bold;
    font-family: $font-primary;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid $color-blue;
    border-bottom: 2px solid $color-blue;
    margin-bottom: -2px;
    padding: 10px 0;

    @include breakpoint(m) {
        border-top: none;
        border-bottom: none;
        margin-bottom: 5px;
    }

    a {
        color: $color-invert;
        text-decoration: none;
        &:hover, 
        &:active, 
        &:focus {
            text-decoration: underline;
        }
    }
}
.footer-links-expander {
    background-color: transparent;
    border: none;
    height: 30px;
    width: 30px;

    @extend %chevron;
    &::before {
        border-color: $color-invert;
    }

    @include breakpoint(m) {
        display: none;
    }
    
}
.footer-links {
    @include resetList;
    margin-top: 10px;
    margin-bottom: 20px;

    @include breakpoint(m) {
        display: block !important;
        margin-top: 0;
    }
}
.footer-links__item {
    margin-bottom: 10px;
}
.footer-links__link {
    color:$color-invert;
    text-decoration: none;
    &:hover, 
    &:active, 
    &:focus {
        text-decoration: underline;
    }
}

.footer-copyright {
    text-align: center;
    font-size: pxtorem(11px);
    margin: 15px 0 10px;

    @include breakpoint(mw) {        
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: pxtorem(14px);
    }
}
.footer-copyright-text {
    margin: 0 0 5px;
    display: block;

    @include breakpoint(mw) {        
        margin: 0 20px 7px 0;
        display: inline;
    }
}
.footer-copyright-link {
    display: block;
    color: $color-invert;
    text-decoration: underline;
    position: relative;
    display: inline-block;
    margin: 0 22px 7px 0;

    @include breakpoint(mw) {  
    }

    &:hover, 
    &:active, 
    &:focus {
        text-decoration: none;
        opacity: .8;
    }
    
    &::before {
        content: "|";
        position: absolute;
        display: inline-block;
        top: 50%;
        transform: translateY(-56%);
        font-weight: 400;
        font-size: 0.9em;
        left: inherit;
        right: -12px;

        @include breakpoint(mw) {
            left: -12px;
            right: inherit;
        }
    }

    &:last-child {
        &::before {
            content: none;
            @include breakpoint(mw) {
                content: "|";
            }
        }
    }
}

.footer-newsletter {
    text-align: center;
    position: relative;
}
.footer-newsletter__inner {
    @include breakpoint(mw) {
        display: flex;
        align-items: center;
    }
}
.footer-newsletter__heading {
    font-size: pxtorem(18px);
    line-height: 1;
    margin-bottom: 15px;

    @include breakpoint(mw) {
        font-size: pxtorem(26px);
        margin-bottom: 0;
    }
}
.footer-newsletter__form {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-invert;
    width: fit-content;
    margin: auto;
    padding: 4px;
    border-radius: 30px;
    width: 520px;
    max-width: 100%;
    position: relative;

    @include breakpoint(mw) {
        margin: 0 34px;
    }
}
.footer-newsletter__input {
    border: none;
    background-color: transparent;
    width: 100%;
    flex: 1;
    border-radius: 30px;
}
.footer-newsletter__button {
    padding: 10px 20px;
    flex: 1;
    max-width: 190px;
}
.footer-newsletter__subscribe-message {
    position: relative;
    bottom: -25px;
    left: 0;
    right: 0;
    @include breakpoint(mw) {
        bottom: 0;
    }
}

/******************************** 
** Footer Social List 
********************************/
.footer-social {
    position: relative;
    margin: 40px 0;

    @include breakpoint(mw) {
        display: flex;
        margin: 60px 0;
    }
}

.footer-social-list {
    @include resetList;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 44px;

    @include breakpoint(mw) {
        margin-left: auto;
        justify-content: end;
        margin-top: 0;
    }
}
.footer-social-list__item {
    margin: 0 10px;
}
.footer-social-list__link {
    &:hover, 
    &:active, 
    &:focus {
        opacity: .8;
    }
}
.footer-social-list__icon {
    width: 24px;
    height: 24px;
    fill: $color-invert;
}