/* ================================================================
   Ribbon CTA
================================================================ */

.ribbon-cta {
	padding: 0 $grid-gutter;
	text-align: center;

	@include breakpoint(mw) {
		display: flex;
		align-items: center;
    	justify-content: center;
	}
}
.ribbon-cta__title {
	font-weight: $fontWeight-normal;
	font-size: pxtorem(24px);
	text-align: center;
	line-height: 1.2;
	margin-bottom: 30px;
	
	@include breakpoint(mw) {
		font-size: pxtorem(48px);
		margin-right: 42px;
		margin-bottom: 0;
	}
}
.ribbon-cta__btn {
	width: 280px;
	max-width: 100%;
}