/* ================================================================
   News - Majority of styling is done as part of the lists.scss
   ================================================================ */

.news-articles {
    margin: 2rem 0;
}

.news-article {
    padding: 2rem;
    margin: 1rem 0;
    background: $color-light-gray;
    border-radius: 10px;

    &__category {
        padding: .25rem .5rem;
        background: $color-secondary;
        color: $color-invert;
        @include x-rem(font-size, 14px);
    }

    &__title {
        @include x-rem(font-size, 22px);
        line-height: 1;
        margin: .5rem 0;
    }

    &__date {
        font-style: italic;
        @include x-rem(font-size, 14px);
    }

    &__shortDesc {
        margin: 1rem 0;
    }

    &__link {
        color: $color-orange;
    }
}
