.expert-detail-section__header {
font-family: 'Barlow';
font-style: normal;
font-weight: 500;
@include x-rem(font-size, 24px);
line-height: 30px;
text-align: center;
letter-spacing: -0.25px;
color: #FFFFFF;
margin-bottom: 20px;
&--black {
    color: #000000;
}
}
.expert-detail-section__video {
width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expert-detail-section__video iframe {
      width: 100%;
    height: 100%;
    aspect-ratio: 1920 / 1080;
    max-width: 930px;
    border: none;
}
.expert-detail {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 90px;
}
.expert-detail__image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 200px;
  min-width: 200px;
  aspect-ratio: 1 / 1;
  flex: 1 1 20%;
  border-radius: 50%;
   
        max-width: 335px
    
}
.expert-detail__content {
    display: flex;
    flex-direction: column;
     flex: 1 60%;
}
.expert-detail__title {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  @include x-rem(font-size, 32px);
  line-height: 38px;
  letter-spacing: -0.5px;
  color: #000000;
  margin: 0 0 10px 0;
}
.expert-detail__subtitle {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 300;
  @include x-rem(font-size, 20px);
  line-height: 30px;
  color: #000000;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  &::after {
    @include pseudo;
    width: 100%;
    height: 2px;
    background: #e0e0e0;
    bottom: 0;
  }
}
.expert-detail-cta-list {
      @include resetList;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 38px;
  row-gap: 15px;
  margin-bottom: 20px;

}
.expert-detail-cta-list__item {
//   flex: 1 1 41%;
//   display: flex;
//   align-items: center;
//   gap: 4px;
max-width: fit-content;
}

.expert-detail-cta-list__item {
  flex: 1 1 41%;
}
.expert-detail-cta-list__item a {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  font-family: $font-primary;
  font-style: normal;
  font-weight: 700;
  font-size: pxtorem(16px);
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.316667px;
  color: $color-primary;
  text-decoration: none;

  &:hover, 
  &:active, 
  &:focus {
    opacity: .8;
  }
  @include breakpoint(mw) {
    font-size: pxtorem(19px);
  }
}
.expert-detail-cta-list__item a svg {
  fill: $color-primary;
  color: $color-primary;
  height: 22px;
  width: 22px;
}
.expert-detail__subheader {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 700;
  @include x-rem(font-size, 18px);
  line-height: 24px;
  color: #212121;
  margin-bottom: 10px;
}

.expert-detail__list {
  display: flex;
  flex-direction: column;
  margin: 0;
  row-gap: 10px;
  margin-bottom: 20px;
}
.expert-detail__list li {
}

.expert-detail__text {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  @include x-rem(font-size, 16px);
  line-height: 24px;
  color: #212121;
  margin-bottom: 30px;
}

.expert-detail-link-list {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

   @include resetList;
}
.expert-detail-link-list__item {
}
.expert-detail-link-list__item a {
  font-family: $font-primary;
  font-style: normal;
  font-weight: 400;
  @include x-rem(font-size, 16px);
  line-height: 28px;
  text-decoration-line: underline;
  color: $color-primary;
}

@include breakpoint($bp-l) {
  .expert-detail-section__header {
@include x-rem(font-size, 48px);
margin-bottom: 60px;
line-height: 54px;
}
   .expert-detail__title {
    @include x-rem(font-size, 60px);
line-height: 70px;
   }
   .expert-detail__subtitle {
    @include x-rem(font-size, 24px);
line-height: 32px;
   }
   .expert-detail__subheader {
     @include x-rem(font-size, 20px);
line-height: 28px;
   }
}