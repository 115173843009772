$side-by-side-margin: 50px;
$side-by-side-margin-l: 150px;
$side-by-side-margin-dsk: 60px;
$side-by-side-border-radius-mobile: 30px;
$side-by-side-border-radius-desktop: 40px;

.side-by-side {
    padding: 35px 0;

    @include breakpoint(mw) {        
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        padding: 55px 0;
    }

    .btn {
        display: block;
        @include breakpoint(mw) {            
            display: inline-block;
        }
    }
}

.side-by-side__figure {
    position: relative;
    margin-right: 0;
    margin-bottom: 25px;
    border-radius: $side-by-side-border-radius-mobile;
    height: 100%;
    overflow: hidden;

    @include breakpoint(mw) {        
        flex: 1 1 auto;
        margin-right: $side-by-side-margin;
        margin-bottom: 0;
        border-radius: $side-by-side-border-radius-desktop;
    }
    @include breakpoint(l) {    
        margin-right: $side-by-side-margin-l;
    }

    .side-by-side__photo {
        width: 100%;
    }
}

.side-by-side__img {
    position: relative;
    background: no-repeat center center / cover;
    @include lazy-fade-in($color-invert);
    height: 100%;

    &::after {
        content: "";
        display: block;
        padding-top: percentage(478/570);
    }
}

.side-by-side__box {
    @include breakpoint(mw) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex: 0 0 auto;
        width: 49%;
        padding-right: $grid-gutter-em;
    }
}

.side-by-side__content {

    @include breakpoint(mw) {        
        width: 100%;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}
.side-by-side__text {
    font-size: pxtorem(16px);
    margin-bottom: 30px;

    h3 {
        font-size: pxtorem(21px);
        margin-bottom: 20px;
        @include breakpoint(mw) { 
            font-size: pxtorem(36px);
            line-height: 42px;
        }
    }
    h4 {
        font-size: pxtorem(18px);
        margin-bottom: 5px;
        font-weight: $fontWeight-bold;
    }
    p > a {
        font-weight: $fontWeight-bold;
    }
    ul {
        margin: 25px 0;
    }
    li {
        margin-bottom: 10px;
    }
}

.side-by-side__links {
    text-align: center;

    @include breakpoint(mw) {        
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .btn {
        margin: auto;
        max-width: 270px;
        margin-bottom: 25px;

        @include breakpoint(mw) {            
            margin: 0 10px 0 0;
        }
    }
}


.side-by-side--full-width-img {
    @include breakpoint(mw) {
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        overflow: hidden;
    
        .side-by-side__content {
            max-width: calc( (#{$container-size})/2);
        }
    }
    .side-by-side__figure {
        border-radius: 0 $side-by-side-border-radius-mobile $side-by-side-border-radius-mobile 0;
        margin-left: -#{$grid-gutter-em};
    
        @include breakpoint(mw) { 
            border-radius: 0 $side-by-side-border-radius-desktop $side-by-side-border-radius-desktop 0;
            margin-left: 0;
        }        
    }
    .side-by-side__img { 
        &::after {
            content: "";
            display: block;
            padding-top: percentage(283/345);
            @include breakpoint(mw) {
                min-height: 690px;
                padding-top: 0;
            }
        }
    }
}


.side-by-side--flipped {
    .side-by-side__figure {
        margin-left: 0;

        @include breakpoint(mw) {  
            order: 2;
            margin-right: 0;
            margin-left: $side-by-side-margin;
        }
        @include breakpoint(l) {    
            margin-left: $side-by-side-margin-l;
        }
    }
    
    .side-by-side__content {
        @include breakpoint(mw) {
            max-width: calc( (#{$container-size})/2 - 50px);
        }
    }
    
    &.side-by-side--full-width-img {
        @include breakpoint(mw) {    
            .side-by-side__box {
                justify-content: flex-end;
                padding-left: $grid-gutter-em;
            }
        }
        .side-by-side__figure {
            margin-right: -#{$grid-gutter-em};
            border-radius: $side-by-side-border-radius-mobile 0 0 $side-by-side-border-radius-mobile;
        
            @include breakpoint(mw) { 
                border-radius: $side-by-side-border-radius-desktop 0 0 $side-by-side-border-radius-desktop;
            }
        }
    }
}
.side-by-side__figure .video-modal,
.side-by-side__figure .video-iframe-modal {
    .play {
        transition: $trans;
        opacity: .8;
    }
    &:hover, 
    &:active, 
    &:focus {
        .play {
            transform: scale(1.05);
            opacity: 1;
        }
    }
}

.side-by-side__date-time {
    margin-bottom: 15px;
    @include breakpoint(mw) {
        display: flex;
    }
}
.side-by-side__date,
.side-by-side__time {
    display: flex;
    margin-right: 32px;
    margin-bottom: 18px;
    
    @include breakpoint(mw) {
        margin-bottom: 0;
    }

    svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        flex: 0 0 20px;
    }
}

/******************************** 
** List with buttons 
********************************/
.side-by-side {
    ul.side-by-side__listing {
        @include resetList;
        margin: 25px 0;
        @include breakpoint(mw) {
            columns: 2;
        }
        
        li {
            break-inside: avoid-column;
            margin-bottom: 15px;

            a {
                @extend .btn, .btn--white;
                box-shadow: 0 0 6px 3px rgba(0,0,0,.1);
                width: 100%;
                text-align: left;
                position: relative;
                display: flex;
                align-items: center;

                &:before {
                    content: "";
                    border-color: $color-primary;
                    border-style: solid;
                    border-width: 2px 2px 0 0;
                    height: 12px;
                    width: 12px;
                    display: block;
                    position: relative;            
                    transform: rotate( 45deg );
                    -webkit-transform: rotate( 45deg );
                    order: 2;
                    flex-shrink: 0;
                }
                &:after {
                    content: "";
                    background: $color-primary;
                    display: block;
                    height: 2px;
                    position: relative;
                    width: 16px;
                    order: 1;
                    right: -12px;
                    flex-shrink: 0;
                }

                &:hover, 
                &:active, 
                &:focus {
                    @extend .btn;
                    display: flex;
                    text-align: left;
                    opacity: 1;

                    &::after {
                        background-color: $color-invert;
                    }
                    &::before {
                        border-color: $color-invert;
                    }
                }
            }
        }
    }
} 

/******************************** 
** Custom Video Embed CSS 
********************************/
.video-embed {
    display: none;
}
.brightcove-embed .modaal-content-container {
    padding: 0;
}
.brightcove-embed .modaal-container {
    overflow: hidden !important;
    background-color: #000;
}