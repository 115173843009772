/* ================================================================
   Book Listing Store
================================================================ */

.book-listing__list {
   @include resetList;
}
.book-listing__item {
   &:not(:last-child) {
      margin-bottom: 20px;
   }
}
.book-listing__book {
   background-color: $color-invert;
   display: flex;
   flex-direction: column;
   border-radius: 30px;
   padding: 20px;
   @include breakpoint(mw) {
       flex-direction: row;
       align-items: center;
       padding: 60px;
       border-radius: 40px;
   }
}

.book-listing__book-cover-figure {
   width: 150px;
   margin: auto auto 20px;
   @include breakpoint(mw) {
       width: 200px;
       margin-bottom: 0px;
   }
}
.book-listing__book-cover-img {
   display: block;
   background: center center / cover no-repeat;
   &:after {
      content: "";
      display: block;
      padding-top: percentage(210/150);
   }
}
.book-listing__book-content {
   text-align: center;
   flex: 1;
   @include breakpoint(mw) {
      text-align: left;
      margin: 0px 40px;
   }
}
.book-listing__book-author {
   text-transform: uppercase;
}
.book-listing__book-amazon {
   padding-top: 20px;
   width: 100%;
   text-align: center;
   margin: 20px auto auto;
   border-top: 1px solid $color-gray;
   @include breakpoint(mw) {
      margin-top: auto;
      padding-top: 0px;
      width: 150px;
      border-top: 0px;
   }
}