.breadcrumbs {
    @include resetList;
    @include x-rem(font-size, 14px);
    text-transform: uppercase;
    font-family: $font-secondary;
    letter-spacing: 1.75px;
    padding-left: 40px;
    @include breakpoint(mw) {
        padding-left: 0;
    }
}

.breadcrumbs__item {
    position:relative;
    display: inline-block;
    padding-right: 30px;

    &::after {
        content: "";
        border-style: solid;
        border-width: 0.2em 0.2em 0 0;
        height: 0.5em;
        width: 0.5em;
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        right: 10px;
        transform: rotate(45deg);
        border-color: currentColor;
    }

    &:last-child {
        padding: 0;

        &::after { content: none; }
    }
}

.breadcrumbs__link {
    display: inline-block;
    font-weight: $fontWeight-bold;
    text-decoration: none;
    &:hover, 
    &:active, 
    &:focus {
        text-decoration: underline;
    }
}

.breadcrumbs__active {
}

@media (min-width: $bp-mw) {
    .breadcrumbs {
        display: block;
    }
}

.section-container--blue,
.section-container--dark-blue,
.section-container--green {
    
    .breadcrumbs__link,
    .breadcrumbs__item {
        color: $color-invert;
    }
}