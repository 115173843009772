.side-by-side-slider-container-wrapper {
    position: static;
    z-index: 0;
    margin-top: 40px;
}
.side-by-side-slider-container {
    border-radius: 20px;
    background-color: $body-bg;
    box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.2485);
    overflow: hidden;
}
.side-by-side-slider {
    @include resetList;
    margin-bottom: 102px;
}
.side-by-side-slider .slick-dots {
    @include resetList;
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 15px;
    padding: 40px 0;
    position: absolute;

    bottom: -102px;
    border-radius: 30px;
    background-color: $color-invert;
}

.side-by-side-slider .slick-dots li button {
    @include resetBtn;
    border: 2px solid $color-primary;
    width: 15px;
    height: 15px;
    font-size: 0;
    border-radius: 50%;
}

.side-by-side-slider .slick-dots li.slick-active button {
    background-color: $color-primary;
}
.side-by-side-slider__slide {
    display: flex !important;
    flex-wrap: wrap;
}
.side-by-side-slider__slide-image-container {
    display: flex;
    border-radius: 20px 20px 0px 0px;
    height: 100%;
    width: 100%;
    padding-bottom: 292px;
    position: relative;
    overflow: hidden;
}
.side-by-side-slider__slide-image {
    height: 100%;
    width: 100%;
    min-height: 292px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

/* slider image position fix for laptop sizes - see SLII page for example*/
@media (min-width: 560px) and (max-width: 1140px) {
    .side-by-side-slider__slide-image {    
        background-position-y: 20%;    
    }    
}

.side-by-side-slider__slide-content {
    padding: 30px 20px 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.side-by-side-slider__slide-title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    @include x-rem(font-size, 32px);
    line-height: 38px;
    letter-spacing: -0.5px;
    color: #212121;
    margin-bottom: 0;
}
.side-by-side-slider__slide-description {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 400;
    @include x-rem(font-size, 16px);
    line-height: 24px;
    color: #212121;
}

@include breakpoint($bp-mw) {
    .side-by-side-slider-container {
        border-radius: 40px;
    }
    .side-by-side-slider .slick-dots {
        gap: 20px;
        padding-bottom: 60px;
    }

    .side-by-side-slider__slide-image-container {
        border-radius: 40px;
        //     flex: 1 1 30%;
    }

    .side-by-side-slider__slide-content {
        padding: 90px 120px;
        padding-bottom: 120px;
        padding-left: 50px;
        flex: 1 1 70%;
    }

    .side-by-side-slider__slide-title {
        @include x-rem(font-size, 60px);
        line-height: 70px;
    }
    .side-by-side-slider__slide-description {
        line-height: 28px;
    }
}

@include breakpoint($bp-l) {
    .side-by-side-slider-container-wrapper {
        position: static;
        z-index: 0;
        margin-top: 40px;
    }
    .side-by-side-slider {
        @include resetList;
        margin-bottom: 0;
    }
    .side-by-side-slider .slick-dots {
        bottom: 0;
        padding-bottom: 40px;
        background-color: transparent;
    }
    .side-by-side-slider__slide-image-container {
        border-radius: 40px;
        flex: 1 1 40%;
        padding-bottom: 580px;
    }
    .side-by-side-slider-container-wrapper--flipped .side-by-side-slider__slide-image-container {
        order: 2;
    }

    .side-by-side-slider__slide-content {
        flex: 1 1 60%;
    }
    .side-by-side-slider-container-wrapper--flipped .side-by-side-slider__slide-content {
        padding: 90px 120px;
        padding-right: 50px;
        order: 1;
    }
}

/******************************** 
** Slider container fixes for usage in an overlapping section 
********************************/
.section-container--flyout:has(.side-by-side-slider-container),
.section-container--flyout .container:has(.side-by-side-slider-container),
.section-container--flyout .col-md-12:has(.side-by-side-slider-container) {
    padding: 0;
    border-radius: 20px;
    @include breakpoint(mw) {
        border-radius: 40px;
    }
}
.section-container--flyout:has(.side-by-side-slider-container) {
    box-shadow: 0px 2px 8px rgba(51, 51, 51, 0.3);
}
.section-container--flyout .row:has(.side-by-side-slider-container) {
    margin: 0;
}
.section-container--flyout {
    .side-by-side-slider-container-wrapper {
        margin-top: 0;
    }
    .side-by-side-slider-container {
        box-shadow: none;
    }
}
